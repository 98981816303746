import { useLocation, useNavigate } from 'react-router-dom'

import useSSO from './useSSO'
import useOnLoadSSO from './useOnLoadSSO'
import { SSOForgottenPasswordComponent } from '../components'

const SSOForgottenPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const sso = useSSO()
  const { unauthenticationIsConfirmed } = useOnLoadSSO(
    sso.getDataForAnalytics,
    sso.redirect,
  )

  const handleGoToLogin = async () => {
    navigate(`/sso-login${location.search}`)
  }

  const cancel = () => {
    sso.redirectWithNoSession()
  }

  return (
    <SSOForgottenPasswordComponent
      loading={!unauthenticationIsConfirmed}
      logoUrl={sso.getMediaLogoUrl()}
      customTitle={sso.getMediaTitle()}
      customDescription={sso.getMediaDescription()}
      onCancel={cancel}
      goToLogin={handleGoToLogin}
    />
  )
}

export default SSOForgottenPassword
