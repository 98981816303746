import theme from '../../../../theme'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 4,
    backgroundColor: 'neutral.300',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  toolbar: {
    width: (theme) => `min(100%, ${theme.spacing(90)})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pl: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
    pr: {
      xs: 0,
      md: 1,
      lg: 2,
    },
  },
  wrapLogo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: {
      md: 1.5,
      lg: 2,
    },
  },
  logo: {
    display: 'flex',
    width: (theme) => ({
      xs: theme.spacing(3.75),
      md: 'auto',
    }),

    '&.MuiButton-root': {
      p: 0,

      '&:hover': {
        backgroundColor: 'neutral.300',
      },
    },
  },
  wrapCta: {
    position: {
      xs: 'absolute',
      md: 'static',
    },
    top: (theme) => theme.spacing(3.0625),
    right: 0,
    zIndex: 2,
  },
  confirm: {
    '& .MuiPaper-root': {
      alignSelf: {
        xs: 'flex-start',
        md: 'center',
      },
      rowGap: 1.5,
      width: '100vw',
      maxWidth: (theme) => ({
        xs: '100vw',
        md: theme.spacing(23.4375),
      }),
      pt: 2,
      px: 2,
      pb: 2.5,
      height: 'auto',
      borderTopLeftRadius: (theme) => ({ md: theme.spacing(2) }),
      borderTopRightRadius: (theme) => ({ md: theme.spacing(2) }),
      borderBottomLeftRadius: (theme) => theme.spacing(2),
      borderBottomRightRadius: (theme) => theme.spacing(2),
    },
  },
  confirmTitle: {
    ...theme.typography.h4,
    color: 'neutral.700',
  },
  confirmActions: {
    display: 'flex',
    columnGap: 1,
  },
}

export default styles
