import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Button, Link as MUILink } from '@mui/material'

import { useTexts } from '../../../texts'
import styles from './cookiesLayer.styles'

const CookiesLayer = () => {
  const texts = useTexts()

  const [isVisibleCookies, setIsVisibleCookies] = useState(false)
  //   const [isAcceptedCookies, setIsAcceptedCookies] = useState(false)

  const handleRefuse = () => {
    window.localStorage.setItem('cookies-accepted', false)
    setIsVisibleCookies(false)
  }

  const handleAccept = () => {
    window.localStorage.setItem('cookies-accepted', true)
    setIsVisibleCookies(false)
    // setIsAcceptedCookies(true)
  }

  useEffect(() => {
    if (window.localStorage.getItem('cookies-accepted') === null) {
      setIsVisibleCookies(true)
    }
  }, [])

  //   useEffect(() => {
  //     if (isAcceptedCookies) {
  //       //initialize tag manager, use 'react-gtm-module' ??
  //     }
  //   }, [isAcceptedCookies])

  const boxStyles = isVisibleCookies
    ? { ...styles.cookiesLayer, ...styles.isVisible }
    : styles.cookiesLayer
  return (
    <Box role='dialog' aria-label={texts.getCookiesAriaLabel()} sx={boxStyles}>
      <Box sx={styles.content}>
        <Typography component='h3' sx={styles.title}>
          <span aria-hidden='true'>🍪 </span>
          {texts.getCookiesTitle()}
        </Typography>

        <Typography sx={styles.text}>
          {texts.getCookiesContent()}
          <MUILink to='/cookies-policy' component={Link} sx={styles.text}>
            {texts.getCookiesContent2()}
          </MUILink>
        </Typography>
      </Box>
      <Box sx={styles.actions}>
        <Button
          size='small'
          fullWidth
          variant='containedTertiary'
          onClick={handleRefuse}
        >
          {texts.getRefuseCTA()}
        </Button>

        <Button color='secondary' size='small' fullWidth onClick={handleAccept}>
          {texts.getAcceptCTA()}
        </Button>
      </Box>
    </Box>
  )
}

export default CookiesLayer
