import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import useTexts from '../../../../../texts/useTexts'
import { formatNumber } from '../../../../format'

import { ReactComponent as Coins } from './Coins.svg'
import { ReactComponent as Plus } from './Plus.svg'
import styles from './cta.styles'

const ctaPropTypes = {
  disabled: PropTypes.bool,
  balance: PropTypes.object,
}

const Cta = ({ disabled, balance }) => {
  const texts = useTexts()

  return (
    <Button
      id='header-top-up-btn'
      aria-label={texts.getBalanceTopUpAction()}
      disabled={disabled}
      sx={styles.cta}
      component={Link}
      role='button'
      to='/dashboard/top-up-balance'
    >
      <Coins />
      {balance.isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <Typography sx={styles.amount}>
          {formatNumber(balance.data)}
          <b>€</b>
        </Typography>
      )}
      {disabled ? null : (
        <Box component='span' sx={styles.wrapPlus}>
          <Plus />
        </Box>
      )}
    </Button>
  )
}

Cta.propTypes = ctaPropTypes

export default Cta
