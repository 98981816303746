import { useTexts } from '../../texts'
import { Feedback, CircularProgress } from '../components'
import useSocialLoginCallback from './useSocialLoginCallback'

const REDIRECTION_PATH_ON_SUCCESS = '/success-social-login'

const SocialLoginCallback = () => {
  const texts = useTexts()

  const { isVerifyingSocialLogin, errorDescription } = useSocialLoginCallback(
    REDIRECTION_PATH_ON_SUCCESS,
  )

  if (isVerifyingSocialLogin) return <CircularProgress height='80vh' />
  return (
    <Feedback
      isAuthenticated={false}
      title={texts.getSomethingWentWrontErrorTitle()}
      text={texts.getSomethingWentWrontErrorSubtitle(errorDescription)}
      button={{
        text: texts.getNotFoundErrorCTA(),
        to: '/',
      }}
    />
  )
}

export default SocialLoginCallback
