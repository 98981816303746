import { useEffect, useState } from 'react'
import { Public } from '../../../../business'

import PoliciesLinks from '../../../components/PoliciesLinks'
import Wrapper from './Wrapper'
import FormView from './FormView'
import ErrorView from './ErrorView'
import SuccessView from './SuccessView'

const FORM_ID = 'public-contact-form'

const Footer = () => {
  const { status, isSubmitSuccess, submitForm, resetState } =
    Public.useContactForm(FORM_ID)

  const [isSended, setIsSended] = useState(false)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  useEffect(() => {
    if (isSubmitSuccess() || Boolean(status.formError)) {
      setIsSended(true)
    } else {
      setIsSended(false)
    }
  }, [status, isSubmitSuccess])

  return (
    <Wrapper isSended={isSended} id='public-contact-form'>
      {isSubmitSuccess() ? (
        <SuccessView onClickNewContact={resetState} />
      ) : Boolean(status.formError) ? (
        <ErrorView onClickRetry={resetState} />
      ) : (
        <FormView formId={FORM_ID} {...status} onSubmit={handleSubmit} />
      )}
      <PoliciesLinks />
    </Wrapper>
  )
}

export default Footer
