import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Modal from '../Modal/Modal'
import {
  UserStep,
  ConfirmationCodeStep,
  BankCardStep,
  TopUpStep,
  SuccessStepWithAmount,
} from './Steps'
import styles from './registration.styles'

const TOP_UP_STEP_INDEX = 3

const STEP_MODAL_PROPS = [
  {
    currentStep: 0,
    stepName: 'register-user-data',
    caption: (texts) => texts.getRegisterTitleLabel(),
    title: (texts) => texts.getRegisterSubtitleLabel(),
    background: 'solid',
    sx: styles.successStep,
  },
  {
    currentStep: 0,
    stepName: 'register-confirm-user-email',
    caption: (texts) => texts.getRegisterCodeVerificationLabel(),
    title: (texts) => texts.getRegisterCodeVerificationSubtitleLabel(),
    description: (texts) => texts.getRegisterCodeVerificationTextLabel(),
    background: 'solid',
    sx: styles.confirmationCodeStep,
  },
  {
    currentStep: 1,
    stepName: 'register-insert-bank-card',
    caption: (texts) => texts.getBankCardTitleLabel(),
    title: (texts) => texts.getBankCardSubtitleLabel(),
    description: (texts) => texts.getBankCardTextLabel(),
    sx: styles.bankCardStep,
    background: 'gradient',
  },
  {
    currentStep: 2,
    stepName: 'register-top-up',
    caption: (texts) => texts.getRegisterTopUpTitleLabel(),
    title: (texts) => texts.getRegisterTopUpSubtitleLabel(),
    sx: styles.topUpStep,
    background: 'gradient',
  },
  {
    currentStep: undefined, // for not showing Stepper
    stepName: 'register-success',
    caption: (texts) => texts.getRegisterSuccessTitleLabel(),
    title: (texts) => texts.getRegisterSuccessSubtitleLabel(),
    description: (texts) => texts.getRegisterSuccessDescriptionLabel(),
    sx: styles.successStep,
    background: 'solid',
  },
]

const registrationProptypes = {
  initialStepIndex: PropTypes.number,
  renderSocialLogin: PropTypes.func,
  optionalBankCard: PropTypes.bool,
}

const Registration = ({
  initialStepIndex,
  renderSocialLogin,
  optionalBankCard = false,
}) => {
  const navigate = useNavigate()

  const initialStep = {
    idx: initialStepIndex,
    modalProps: STEP_MODAL_PROPS[initialStepIndex],
  }
  const [step, setStep] = useState(initialStep)

  const goToNextStep = (data = {}) =>
    setStep((step) => ({
      idx: step.idx + 1,
      data: { ...step.data, ...data },
      modalProps: STEP_MODAL_PROPS[step.idx + 1],
    }))

  const goToSuccessStep = (data) =>
    setStep((step) => ({
      idx: 4,
      data: { ...step.data, ...data },
      modalProps: STEP_MODAL_PROPS[4],
    }))

  const handleCloseButton =
    step.idx !== 4 ? () => handleCancle(step.idx) : undefined

  const handleCancle = (stepIdx) => {
    document.dispatchEvent(
      new CustomEvent('leaveRegister', { detail: step.modalProps.stepName }),
    )

    if (stepIdx < TOP_UP_STEP_INDEX) {
      navigate('/')
    } else {
      navigate('/dashboard')
    }
  }

  const handleFinishRegister = () => {
    navigate('/dashboard')
  }

  const handleGoToLogin = () => {
    navigate('/login')
  }

  return (
    <div aria-label='crea-tu-cuenta-payper'>
      <Modal onCancel={handleCloseButton} {...step.modalProps}>
        {step.idx === 0 ? (
          <UserStep
            onContinue={goToNextStep}
            onGoToLogin={handleGoToLogin}
            renderSocialLogin={renderSocialLogin}
          />
        ) : null}
        {step.idx === 1 ? (
          <ConfirmationCodeStep
            onContinue={optionalBankCard ? goToSuccessStep : goToNextStep}
            {...step.data}
          />
        ) : null}
        {step.idx === 2 ? (
          <BankCardStep onContinue={goToNextStep} {...step.data} />
        ) : null}
        {step.idx === 3 ? (
          <TopUpStep onContinue={goToNextStep} {...step.data} />
        ) : null}
        {step.idx === 4 ? (
          <SuccessStepWithAmount onContinue={handleFinishRegister} />
        ) : null}
      </Modal>
    </div>
  )
}

Registration.propTypes = registrationProptypes

export default Registration
