import { BankCardChange } from '../components'
import { useTexts } from '../../texts'

const BankCardEdition = () => {
  const texts = useTexts()

  return (
    <BankCardChange
      title={texts.getUpdateBankCardTitleLabel()}
      successTitle={texts.getUpdateBankCardSuccessTitleLabel()}
      successSubtitle={texts.getUpdateBankCardSuccessMessageLabel()}
    />
  )
}

export default BankCardEdition
