import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Session } from '../../business'
import { decode } from '../format'
import {
  CircularProgress,
  PurchaseWithBankCardRegistration,
} from '../components'
import useSSO from './useSSO'

const validateUrlStateValue = (state) => {
  const isValid =
    Boolean(state) &&
    Boolean(state?.article_price) &&
    Boolean(state?.success_redirect_uri) &&
    Boolean(state?.error_redirect_uri)
  if (!isValid) {
    throw new Error(
      'invalid url state content: article_price, success_redirect_uri & error_redirect_uri are required',
    )
  }
  return isValid
}

const getUrlStateValue = (searchParams = '') => {
  const urlSearchParams = new URLSearchParams(searchParams)
  const state = decode(urlSearchParams.get('state'))
  const isValid = validateUrlStateValue(state)
  return isValid ? state : null
}

const WidgetPurchaseWithBankCardRegistration = () => {
  const location = useLocation()
  const searchParams = location.search
  const sso = useSSO()

  const [redirectState, setRedirectState] = useState()

  const handleRedirectOnSuccess = () =>
    (window.location = redirectState.success_redirect_uri)

  const handleRedirectOnError = () =>
    (window.location = redirectState.error_redirect_uri)

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent('ssoBankCardRegistration', {
        detail: sso.getDataForAnalytics(),
      }),
    )
  }, [sso])

  useEffect(() => {
    ;(async () => {
      if (!Session.isAuthenticated()) {
        // TODO: add auth data to url params for this case
        alert('usuario no autenticado')
      } else if (!Session.hasValidAccessToken()) {
        await Session.refreshSession()
      }

      // FIX for not throw error if no searchParams exist
      setRedirectState(getUrlStateValue(searchParams))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!redirectState) return <CircularProgress height='80vh' />

  return (
    <PurchaseWithBankCardRegistration
      onFinishWithSuccess={handleRedirectOnSuccess}
      onFinishWithError={handleRedirectOnError}
      articlePrice={redirectState.article_price}
    />
  )
}

export default WidgetPurchaseWithBankCardRegistration
