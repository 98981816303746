import { useState, useEffect } from 'react'

import { Session } from '../../business'

const useOnLoadSSO = (getDataForAnalytics, redirect) => {
  const [unauthenticationIsConfirmed, setUnauthenticationIsConfirmed] =
    useState(false)

  useEffect(() => {
    ;(async () => {
      const initializeDataForAnalytics = () =>
        document.dispatchEvent(
          new CustomEvent('ssoLoad', { detail: getDataForAnalytics() }),
        )

      if (Session.isAuthenticated()) {
        if (!Session.hasValidAccessToken()) {
          await Session.refreshSession()
        }
        redirect()
      } else {
        initializeDataForAnalytics()
        setUnauthenticationIsConfirmed(true)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { unauthenticationIsConfirmed }
}

export default useOnLoadSSO
