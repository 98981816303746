import { useLocation, useNavigate } from 'react-router-dom'

import { Session, User } from '../../business'
import useSSO from './useSSO'
import useOnLoadSSO from './useOnLoadSSO'
import {
  SSOUserRegistration,
  SSOFullRegistration,
  SSOSocialLoginButtons,
} from '../components'

const SSORegister = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const sso = useSSO()
  const { unauthenticationIsConfirmed } = useOnLoadSSO(
    sso.getDataForAnalytics,
    sso.redirect,
  )

  const navigateToLogin = () => {
    navigate(`/sso-login${location.search}`)
  }

  const handleFinish = async (stepIndex) => {
    if (stepIndex < 2) {
      sso.redirectWithNoSession()
    } else if (sso.hasBonus()) {
      await User.createPayperBonus(sso.getBonusToken())
      sso.redirect({ autoBuyOnRedirect: true })
    } else if (stepIndex === 2) {
      sso.redirect({ resetState: true })
    } else {
      sso.redirect({ autoBuyOnRedirect: true })
    }
  }

  const handleFinishWithError = () => sso.redirect({ autoBuyOnRedirect: false })

  if (Session.isAuthenticated()) {
    sso.redirect()
    return
  }

  const RegistrationStepperComponent = sso.hasBonus()
    ? SSOUserRegistration
    : SSOFullRegistration

  return (
    <RegistrationStepperComponent
      loading={!unauthenticationIsConfirmed}
      logoUrl={sso.getMediaLogoUrl()}
      customTitle={sso.getMediaTitle()}
      customDescription={sso.getMediaDescription()}
      articlePrice={sso.getArticlePrice()}
      onClickLogin={navigateToLogin}
      renderSocialLogin={() => <SSOSocialLoginButtons />}
      onFinish={handleFinish}
      onFinishWithError={handleFinishWithError}
    />
  )
}

export default SSORegister
