import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Session, ApplePay } from '../../../business'
import { CircularProgress } from '../index'

import ModalWithPayperFooter from '../Modal/ModalWithPayperFooter'
import {
  ApplePayTopUp,
  BankCardStepWidget,
  TopUpStepWidget,
  ErrorStep,
} from './Steps'

const STEPS = {
  APPLE_PAY_TOP_UP: 'APPLE_PAY_TOP_UP',
  BANKCARD_REGISTRATION: 'BANKCARD_REGISTRATION',
  TOP_UP: 'TOP_UP',
  ERROR: 'ERROR',
}

const STEP_MODAL_PROPS = {
  APPLE_PAY_TOP_UP: {
    title: (texts) => texts.getApplePayTopUpTitle(),
    background: 'gradient',
    sx: {},
  },
  BANKCARD_REGISTRATION: {
    title: (texts) => texts.getIntroBankCardTitle(),
    background: 'gradient',
    sx: {},
  },
  TOP_UP: {
    title: (texts) => texts.getBalanceTopUpAfterBankCardTitle(),
    description: (texts) => texts.getBalanceTopUpDescription(),
    background: 'gradient',
    sx: {},
  },
  ERROR: {
    title: (texts) => texts.getPurchaseErrorTitle2(),
    description: (texts) => texts.getTopUpErrorSubtitle(),
    background: 'gradient',
    sx: {},
  },
}

const purchaseWithBankCardRegistrationPropTypes = {
  onFinishWithSuccess: PropTypes.func.isRequired,
  onFinishWithError: PropTypes.func.isRequired,
  articlePrice: PropTypes.string.isRequired,
}

const PurchaseWithBankCardRegistration = ({
  onFinishWithSuccess,
  onFinishWithError,
  articlePrice,
}) => {
  const [activeStep, setActiveStep] = useState()
  const [stepOriginatingError, setStepOriginatingError] = useState()

  const userSub = Session.getUserSub()

  const onCloseModal = () => {
    if (
      activeStep === STEPS.BANKCARD_REGISTRATION ||
      (activeStep === STEPS.ERROR &&
        stepOriginatingError === STEPS.BANKCARD_REGISTRATION)
    ) {
      onFinishWithError()
    } else {
      onFinishWithSuccess()
    }
  }

  useEffect(() => {
    ;(async () => {
      if (await ApplePay.hasActiveCard()) {
        setActiveStep(STEPS.APPLE_PAY_TOP_UP)
      } else {
        setActiveStep(STEPS.BANKCARD_REGISTRATION)
      }
    })()
  }, [])

  if (!userSub) {
    return <h1>error: usuario no autenticado</h1>
  }
  if (!Boolean(activeStep)) {
    return <CircularProgress height='80vh' />
  }

  return (
    <ModalWithPayperFooter
      onCancel={onCloseModal}
      {...STEP_MODAL_PROPS[activeStep]}
    >
      {activeStep === STEPS.APPLE_PAY_TOP_UP ? (
        <ApplePayTopUp
          onClickIntroBankCard={() =>
            setActiveStep(STEPS.BANKCARD_REGISTRATION)
          }
          onContinue={onFinishWithSuccess}
        />
      ) : activeStep === STEPS.BANKCARD_REGISTRATION ? (
        <BankCardStepWidget
          sub={userSub}
          articlePrice={articlePrice}
          onContinue={() => setActiveStep(STEPS.TOP_UP)}
        />
      ) : activeStep === STEPS.TOP_UP ? (
        <TopUpStepWidget
          articlePrice={articlePrice}
          onContinue={onFinishWithSuccess}
          onError={() => {
            setActiveStep(STEPS.ERROR)
            setStepOriginatingError(STEPS.TOP_UP)
          }}
        />
      ) : activeStep === STEPS.ERROR ? (
        <ErrorStep
          onRetry={() => {
            setActiveStep(stepOriginatingError)
            setStepOriginatingError()
          }}
          onCancelProcess={onFinishWithError}
        />
      ) : null}
    </ModalWithPayperFooter>
  )
}

PurchaseWithBankCardRegistration.propTypes =
  purchaseWithBankCardRegistrationPropTypes

export default PurchaseWithBankCardRegistration
