export { default as isValidEmail } from './isValidEmail'
export { default as isFilled } from './isFilled'
export { default as isDigit } from './isDigit'
export { default as isChecked } from './isChecked'
export { default as isPhoneMask } from './isPhoneMask'

export const pipeValidators =
  (...functions) =>
  (input) =>
    functions.reduce((acc, fn) => {
      if (acc === false) return acc
      return fn(input)
    }, input)
