import { Box } from '@mui/material'

import AccountData from './AccountData'
import Purchases from './Purchases'
import styles from './dashboard-home.styles'

const DashboardHome = () => {
  return (
    <Box sx={styles.dashboardHome}>
      <AccountData />
      <Purchases />
    </Box>
  )
}

export default DashboardHome
