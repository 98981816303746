import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { Session, Public } from '../../business'
import { encode, decode } from '../format'
import {
  URL_SEARCH_PARAMS_AUTH_KEYS,
  URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS,
} from './constants'

const useSSO = () => {
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const state = searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.STATE)
  const mediaProviderId = searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_ID)
  const article = searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_ARTICLE)
  const redirectUri = searchParams.get(
    URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_REDIRECT_URI,
  )
  const bonus = searchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_BONUS)

  const [mediaProviderData, setMediaProviderData] = useState()

  useEffect(() => {
    ;(async () => {
      const getMediaProvider = async () => {
        if (!Boolean(mediaProviderId)) {
          return
        }

        const data = await Public.getMediaProvider(mediaProviderId)
        setMediaProviderData(data)
      }

      await getMediaProvider()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasBonus = () => Boolean(bonus)

  const getMediaId = () => mediaProviderId

  const getMediaName = () => (mediaProviderData ? mediaProviderData.name : '')

  const getArticlePrice = () =>
    mediaProviderData ? mediaProviderData.articlePrice : '0'

  const getMediaLogoUrl = () => mediaProviderData?.imageUrl
  const getMediaTitle = () => mediaProviderData?.accessClaimTitle
  const getMediaDescription = () => mediaProviderData?.accessClaimDescription

  const getArticleData = () => decode(article)

  const getBonusToken = () => bonus !== 'null' && decode(bonus)

  const getRedirectUrl = (
    { autoBuyOnRedirect, resetState } = {
      autoBuyOnRedirect: false,
      resetState: false,
    },
  ) => {
    const redirectUrlWithGeneralAccessParams =
      Session.appendAccessParamsToURL(redirectUri)

    const widgetRedirectUrl = new URL(redirectUrlWithGeneralAccessParams)
    const searchParams = new URLSearchParams(widgetRedirectUrl.search)

    searchParams.append(
      URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.STATE,
      resetState ? {} : state,
    )

    if (Boolean(autoBuyOnRedirect)) {
      searchParams.append(
        URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.AUTO_PURCHASE_FLAG,
        1,
      )
    }

    return `${widgetRedirectUrl.origin}${
      widgetRedirectUrl.pathname ?? ''
    }?${searchParams.toString()}`
  }

  const redirect = (
    { autoBuyOnRedirect, resetState } = {
      autoBuyOnRedirect: false,
      resetState: false,
    },
  ) => {
    window.location = getRedirectUrl({ autoBuyOnRedirect, resetState })
  }

  const redirectWithNoSession = () => {
    window.location = `${redirectUri}`
  }

  const getSSOThirdPartyIdpStateParam = (currentParams) => {
    const ssoState = {
      ...currentParams,
      [URL_SEARCH_PARAMS_AUTH_KEYS.STATE]: state,
      [URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_ARTICLE]: article,
      [URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_REDIRECT_URI]: redirectUri,
      [URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_ID]: mediaProviderId,
    }

    return encode(ssoState)
  }

  const getDataForAnalytics = () => {
    const { articleId, url, headline } = article ? decode(article) : {}
    return {
      mediaProviderId: getMediaId(),
      mediaProviderName: getMediaName(),
      articleId,
      articleUrl: url,
      articleHeadline: headline,
    }
  }

  return {
    redirect,
    redirectWithNoSession,
    getArticlePrice,
    getArticleData,
    getMediaLogoUrl,
    getMediaTitle,
    getMediaDescription,
    getMediaName,
    getMediaId,
    getSSOThirdPartyIdpStateParam,
    getDataForAnalytics,
    hasBonus,
    getBonusToken,
  }
}

export default useSSO
