import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { validateMerchant, makePayment } from './service'
import ErrorMessage from './ErrorMessage'
import styles from './apple-pay-test.styles'

const APPLE_PAY_VERSION = 1
// NOTE: In versions of Apple Pay JS prior to version 4, the amount of the total must be greater than zero.
// Check for version availability using supportsVersion before setting a zero amount.
const PAYMENT_AMOUNT = '0.01'

const APPLE_PAY_PAYMENT_REQUEST_DATA = {
  total: {
    label: 'Payper',
  },
  countryCode: 'ES',
  currencyCode: 'EUR',
  merchantCapabilities: ['supports3DS'],
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'], // The payment networks the merchant supports.
}

const WithActiveCard = ({ paymentAmount = PAYMENT_AMOUNT }) => {
  const [error, setError] = useState()

  const onClickApplePay = () => {
    const createApplePaySession = () => {
      try {
        const paymentRequestDictionary = APPLE_PAY_PAYMENT_REQUEST_DATA
        paymentRequestDictionary.total.amount = paymentAmount

        const applePaySession = new window.ApplePaySession(
          APPLE_PAY_VERSION,
          paymentRequestDictionary,
        )

        applePaySession.begin()

        // This is the first event that Apple triggers. Here you need to validate the
        // Apple Pay Session from your back-end
        applePaySession.onvalidatemerchant = async (event) => {
          const validationUrl = event.validationURL
          console.log({ event, validationUrl })

          const merchantSession = await validateMerchant(validationUrl)
          console.log({ merchantSession })

          applePaySession.completeMerchantValidation(merchantSession)
        }

        // This triggers after the user has confirmed the transaction with the Touch Id or Face Id
        // This will contain the payment token
        applePaySession.onpaymentauthorized = async (event) => {
          const paymentData = event.payment.token.paymentData // todo: send to redsys
          console.log({
            eventToken: event.payment.token,
            paymentData,
          })

          const { success } = await makePayment(paymentAmount, paymentData)
          const applePaymentStatus = success
            ? window.ApplePaySession.STATUS_SUCCESS
            : window.ApplePaySession.STATUS_FAILURE
          console.log({ success, applePaymentStatus })
          applePaySession.completePayment(applePaymentStatus)
        }
      } catch (error) {
        console.error(error)
        setError(
          `Error creating ApplePaySession: ${error.name}/ ${error.message}`,
        )
      }
    }

    createApplePaySession()
  }

  useEffect(() => {
    const applePayButton = document.querySelector('apple-pay-button')
    applePayButton.addEventListener('click', onClickApplePay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={styles.content}>
      {Boolean(error) && <ErrorMessage error={error} />}
      <div id='apple-pay-button-container'>
        <apple-pay-button
          buttonstyle='black'
          type='buy'
          locale='es-ES'
        ></apple-pay-button>
      </div>
    </Box>
  )
}

export default WithActiveCard
