import { User } from '../../../../business'

import View from './View'
import Balance from './Balance'
import BankCard from './BankCard'
import BankCardIntro from './BankCardIntro'
import Bonus from './Bonus'

const AccountData = () => {
  const { state: bankCard } = User.useBankCard()

  return (
    <View>
      <Bonus />
      <Balance withTopUp={Boolean(bankCard?.data)} />
      {Boolean(bankCard?.data) ? (
        <BankCard bankCardData={bankCard.data} />
      ) : (
        <BankCardIntro />
      )}
    </View>
  )
}

export default AccountData
