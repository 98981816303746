export const PURCHASE_TABLE_HEAD_DATA = [
  {
    field: 'date',
    label: 'Fecha',
    isSortable: false,
  },
  {
    field: 'headline',
    label: 'Noticia',
    isSortable: false,
  },
  {
    field: 'mediaProvider',
    label: 'Medio',
    isSortable: false,
  },
  {
    field: 'price',
    label: 'Importe',
    isSortable: false,
  },
]

export const INITIAL_ROWS_PER_PAGE = 10
export const PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS = [5, 10, 20]
