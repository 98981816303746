const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: {
      md: 0.5,
      lg: 1,
    },
    color: 'neutral.100',
  },
  user: {
    '--circle-backgroundColor': (theme) => theme.palette.neutral[200],
    columnGap: 0.5,
    py: {
      xs: 0.75,
      md: 0.75,
      lg: 1,
    },
    px: {
      xs: 1,
      md: 1.5,
    },
    borderTopRightRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(0.5),
    }),
    borderBottomRightRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(0.5),
    }),

    '&:hover, &:focus-visible': {
      backgroundColor: 'neutral.200',
      '--circle-backgroundColor': (theme) => theme.palette.neutral[300],
    },

    '& .MuiButton-endIcon': {
      ml: 0,
    },
  },
  isOpen: {
    backgroundColor: 'neutral.200',
    '--circle-backgroundColor': (theme) => theme.palette.neutral[300],

    '& .MuiButton-endIcon': {
      transform: 'rotate(180deg)',
      ml: 0,
    },
  },
  logout: {
    width: (theme) => theme.spacing(2.5),
    height: (theme) => theme.spacing(2.5),
    color: 'neutral.500',
    backgroundColor: 'neutral.300',

    '&:hover, &:focus-visible': {
      backgroundColor: 'neutral.200',
      '--circle-backgroundColor': (theme) => theme.palette.neutral[300],
    },
  },
}

export default styles
