import { useRouteError } from 'react-router-dom'

import { Session } from '../../../business'
import { useTexts } from '../../../texts'
import { DynamicLayout } from '../../Layout'
import { Feedback } from '../../components'
import coffeeImageAvif from './coffee-spilling-out-of-mug.avif'
import coffeeImageWebp from './coffee-spilling-out-of-mug.webp'
import coffeeImagePng from './coffee-spilling-out-of-mug.png'

const picture = {
  width: 418,
  height: 367,
  source: {
    avif: coffeeImageAvif,
    webp: coffeeImageWebp,
    fallback: coffeeImagePng,
  },
  styles: {
    maxWidth: '230px',
    height: 'auto',
  },
}

const Error = () => {
  const error = useRouteError()
  const texts = useTexts()
  const isAuthenticated = Session.isAuthenticated()

  const notFoundErrorProps = {
    title: texts.getNotFoundErrorTitle(),
    text: texts.getNotFoundErrorSubtitle(),
    button: { text: texts.getNotFoundErrorCTA(), to: '/dashboard' },
    picture,
    isAuthenticated,
  }

  const unexpectedErrorProps = {
    title: texts.getUnexpectedErrorTitle(),
    text: texts.getUnexpectedErrorSubtitle(),
    button: {
      text: texts.getUnexpectedErrorCTA(),
      to: document.location.pathname,
    },
    secondaryButton: {
      text: texts.getUnexpectedErrorSecondaryButton(),
      to: '/dashboard',
    },
    picture,
    isAuthenticated,
  }

  const viewProps =
    error.status === 404 ? notFoundErrorProps : unexpectedErrorProps

  return (
    <DynamicLayout>
      <Feedback {...viewProps} />
    </DynamicLayout>
  )
}

export default Error
