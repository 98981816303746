import { useLocation, useNavigate } from 'react-router-dom'

import { useTexts } from '../../texts'

import useSSOPartner from './useSSOPartner'
import useOnLoadSSO from './useOnLoadSSO'

import {
  SSOPartnerSocialLoginButtons,
  SSOLogin as SSOLoginComponent,
} from '../components'

const SSO_PARTNER_LOGIN_FORM_ID = 'sso-partner-login-form'

const SSOLoginPartner = () => {
  const texts = useTexts()
  const location = useLocation()
  const navigate = useNavigate()

  const ssoPartner = useSSOPartner()
  const { unauthenticationIsConfirmed } = useOnLoadSSO(
    ssoPartner.getDataForAnalytics,
    ssoPartner.redirect,
  )

  const handleGoToRegister = async () => {
    navigate(`/sso-register-partner${location.search}`)
  }

  const handleGoToForgottenPassword = async () => {
    navigate(`/sso-forgotten-password-partner${location.search}`)
  }

  const handleSuccess = async () => {
    ssoPartner.redirect()
  }

  const cancel = () => {
    navigate('/')
  }

  return (
    <SSOLoginComponent
      loading={!unauthenticationIsConfirmed}
      logoUrl={ssoPartner.getPartnerLogoUrl()}
      customTitle={ssoPartner.getPartnerTitle()}
      customDescription={ssoPartner.getPartnerDescription()}
      onCancel={cancel}
      formId={SSO_PARTNER_LOGIN_FORM_ID}
      onGoToRegister={handleGoToRegister}
      onGoToForgottenPassword={handleGoToForgottenPassword}
      renderSocialLogin={() => <SSOPartnerSocialLoginButtons />}
      onSuccess={handleSuccess}
      registerCTACustomLabel={texts.getLoginPartnerRegisterCtaLabel()}
    />
  )
}

export default SSOLoginPartner
