import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Link as MUILink, Typography } from '@mui/material'

import { useTexts } from '../../../../../texts'
import { Alert, Chip } from '../../../../components'
import CardWrapper from '../CardWrapper'
import { ReactComponent as Bag } from './Bag.svg'
import { ReactComponent as Contactless } from './Contactless.svg'
import { ReactComponent as Pencil } from './Pencil.svg'
import styles from './bankCard.styles.js'

const cardBankPropTypes = {
  bankCardData: PropTypes.shape({
    cardNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    isExpired: PropTypes.bool,
  }),
}

const CardBank = ({ bankCardData }) => {
  const texts = useTexts()

  return (
    <CardWrapper title={texts.getUserBankCardLabel()} icon={<Bag />}>
      <Box sx={styles.card}>
        <Box sx={styles.top}>
          <Box sx={styles.left}>
            <Chip
              text={
                !bankCardData
                  ? texts.getBankCardNotUpdatedLabel()
                  : bankCardData.isExpired
                  ? texts.getBankCardOutdatedLabel()
                  : texts.getBankCardUpdatedLabel()
              }
              variant={
                !bankCardData
                  ? 'default'
                  : bankCardData.isExpired
                  ? 'error'
                  : 'success'
              }
            />
            <Box sx={styles.icon}>
              <Contactless />
            </Box>
          </Box>
          <Box>
            <MUILink
              to='bank-card-edition'
              component={Link}
              sx={styles.iconButton}
              aria-label={texts.getBankCardEditAction()}
            >
              <Pencil />
            </MUILink>
          </Box>
        </Box>

        {bankCardData.isExpired ? (
          <Alert
            variant='error'
            text={texts.getBankCardOutdatedMessageLabel()}
          />
        ) : null}

        <Box sx={styles.bottom}>
          {!bankCardData ? (
            <Typography
              component='span'
              sx={styles.cardData}
            >{`•••• •••• •••• ••••`}</Typography>
          ) : (
            <>
              <Typography component='span' sx={styles.cardData}>
                {`•••• •••• •••• ${bankCardData.cardNumber?.slice(-4)}`}
              </Typography>
              <Typography component='span' sx={styles.cardData}>
                {bankCardData.expiryDate}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </CardWrapper>
  )
}

CardBank.propTypes = cardBankPropTypes

export default CardBank
