import { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

import { Session } from '../../../../../business'
import { useTexts } from '../../../../../texts'
import { User } from '../../../../../business'

import { ReactComponent as CaretCircle } from './CaretCircle.svg'
import { ReactComponent as Logout } from './Logout.svg'
import { ReactComponent as Cardholder } from './Cardholder.svg'
import { ReactComponent as UserCircle } from './UserCircle.svg'

import { MenuContainer, MenuItem } from './Menu'
import styles from './actions.styles'

const HeaderActions = ({ username, onClickLogout }) => {
  const texts = useTexts()
  const theme = useTheme()

  const { state: bankCard } = User.useBankCard()
  const hasBankCard = Boolean(bankCard?.data)

  const user = Session.getUserInfoFromIdToken()

  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpened = Boolean(anchorEl)
  const handleClickMenu = (ev) => setAnchorEl(ev.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  return (
    <Box sx={styles.actions}>
      <Button
        id='user-button'
        endIcon={<CaretCircle />}
        aria-label='user-button'
        aria-controls={menuOpened ? 'user-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={menuOpened ? 'true' : undefined}
        sx={{ ...styles.user, ...(menuOpened ? styles.isOpen : null) }}
        onClick={handleClickMenu}
      >
        {user.name}
      </Button>

      {widerThanTablet ? (
        <IconButton
          aria-label='logout-button'
          onClick={onClickLogout}
          sx={styles.logout}
        >
          <Logout />
        </IconButton>
      ) : null}

      <MenuContainer
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        open={menuOpened}
      >
        <MenuItem
          onClick={handleCloseMenu}
          component={Link}
          to='/dashboard/personal-data-edition'
          icon={<UserCircle />}
          text={texts.getUserDataLabel()}
        />

        <MenuItem
          onClick={handleCloseMenu}
          component={Link}
          to={
            hasBankCard
              ? '/dashboard/bank-card-edition'
              : '/dashboard/bank-card-introduction'
          }
          icon={<Cardholder />}
          text={texts.getUserBankCardLabel()}
        />

        {widerThanTablet ? null : (
          <MenuItem
            onClick={onClickLogout}
            icon={<Logout />}
            text={texts.getUserLogoutAction()}
            aria-label='logout-button'
          />
        )}
      </MenuContainer>
    </Box>
  )
}

export default HeaderActions
