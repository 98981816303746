import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { AppBar, Box, Button } from '@mui/material'

import { useTexts } from '../../../../texts'

import { ReactComponent as Logo } from './logo-payper.svg'
import styles from './header.styles'

const Appbar = ({ onGoToLogin, onGoToRegister, scrollPosition }) => {
  const texts = useTexts()

  return (
    <AppBar sx={styles.root} id='dashboard-header'>
      <Box sx={styles.toolbar}>
        <Box sx={styles.logo} component={Link} to='/'>
          <Logo aria-label='Payper logo' />
        </Box>
        <Box sx={styles.actions}>
          <Button
            id='header-register-btn'
            onClick={onGoToRegister}
            sx={styles.register}
          >
            {texts.getRegisterCtaAction()}
          </Button>
          <Button
            id='header-login-btn'
            color='secondary'
            onClick={onGoToLogin}
            sx={styles.login}
          >
            {texts.getLoginFormCtaAction()}
          </Button>
        </Box>
      </Box>
      <Box sx={styles.track} style={{ '--scrollPosition': scrollPosition }} />
    </AppBar>
  )
}

Appbar.propTypes = {
  onGoToRegister: PropTypes.func,
  onGoToLogin: PropTypes.func,
}

export default Appbar
