import { backofficeEndpoint } from '../../../config'

export const validateMerchant = async (validationUrl) => {
  const response = await fetch(
    `${backofficeEndpoint}/payment-apple/merchant-validation`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ validationUrl }),
    },
  )

  if (response.ok) {
    return response.json()
  }
  throw new Error(`Error fetching ApplePay merchant session: ${response.error}`)
}

export const makePayment = async (amount, paymentData) => {
  const response = await fetch(`${backofficeEndpoint}/payment-apple/payment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ amount, paymentData }),
  })
  console.log({ response })

  if (response.ok) {
    return { success: true }
  } else {
    return { success: false }
  }
}
