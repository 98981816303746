import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import useTexts from '../../../../../texts/useTexts.js'

import { formatPrice } from '../../../../format.js'
import Picture from '../../../Picture/index.js'

import ContinueButton from '../ContinueButton/index.js'
import emptyImageAvif from './empty-box.avif'
import emptyImageWebp from './empty-box.webp'
import emptyImagePng from './empty-box.png'
import topUpImageAvif from './computer.avif'
import topUpImageWebp from './computer.webp'
import topUpImagePng from './computer.png'
import styles from './success.styles.js'

const successStepWithAmountPropTypes = {
  amount: PropTypes.number,
  onContinue: PropTypes.func.isRequired,
}

const SuccessStepWithAmount = ({ amount, onContinue }) => {
  const texts = useTexts()

  return (
    <>
      {!Boolean(amount) ? (
        <Box sx={styles.wrapPicture}>
          <Picture
            width={360}
            height={240}
            avif={emptyImageAvif}
            webp={emptyImageWebp}
            fallback={emptyImagePng}
          />
        </Box>
      ) : (
        <Box sx={styles.wrapPicture}>
          <Typography sx={styles.pictureText}>
            {formatPrice(amount)}
            <br />
            <span>{texts.getSuccessLabel()}</span>
          </Typography>
          <Picture
            width={320}
            height={240}
            avif={topUpImageAvif}
            webp={topUpImageWebp}
            fallback={topUpImagePng}
          />
        </Box>
      )}
      <Box sx={styles.action}>
        <ContinueButton id='register-success-btn' onClick={onContinue}>
          {texts.getRegisterSuccessContinueAction()}
        </ContinueButton>
      </Box>
    </>
  )
}

SuccessStepWithAmount.propTypes = successStepWithAmountPropTypes

export default SuccessStepWithAmount
