import { SSOThirdPartyIdpRegistration } from '../components'

import { Session } from '../../business'
import useSSO from './useSSO'

const SSOThirdPartyIdpRegister = () => {
  const sso = useSSO()
  const userSub = Session.getUserSub()

  const handleFinish = async (stepIndex) => {
    if (stepIndex === 2) {
      sso.redirect({ resetState: true })
    } else {
      sso.redirect({ autoBuyOnRedirect: true })
    }
  }

  const handleFinishWithError = () => sso.redirect({ autoBuyOnRedirect: false })

  if (!userSub) return <h1>error: usuario no autenticado</h1>

  return (
    <SSOThirdPartyIdpRegistration
      userSub={userSub}
      logoUrl={sso.getMediaLogoUrl()}
      customTitle={sso.getMediaTitle()}
      customDescription={sso.getMediaDescription()}
      articlePrice={sso.getArticlePrice()}
      onFinish={handleFinish}
      onFinishWithError={handleFinishWithError}
    />
  )
}

export default SSOThirdPartyIdpRegister
