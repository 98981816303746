import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Auth, Session } from '../../business'
import { CircularProgress } from '../components'
import { URL_SEARCH_PARAMS_AUTH_KEYS } from './constants'

const SuccessSocialLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const urlSearchParams = new URLSearchParams(location.search)
  const code = urlSearchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE)

  useEffect(() => {
    ;(async () => {
      const tokens = await Auth.getJWTs(code)

      const { hasSystemUser } = await Auth.verifyCompleteRegistration(
        tokens.accessToken,
      )

      Session.storeTokens(tokens)

      if (!hasSystemUser) {
        await Auth.thirdPartyIdpRegister(
          tokens,
          Auth.getConvertionParams(location),
        )
        navigate('/3rd-party-idp-register')
        return
      }

      navigate('/dashboard')
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return <CircularProgress height='80vh' />
}

export default SuccessSocialLogin
