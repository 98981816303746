import theme from '../../../../../theme'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    p: (theme) => theme.spacing(1, 1.25, 1, 2.5),
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.25,
  },
  title: {
    ...theme.typography.body3,
    color: 'neutral.600',
  },
  text: {
    ...theme.typography.body3,
    fontWeight: 400,
    color: 'neutral.500',
  },
  list: {
    my: 0,
    paddingLeft: (theme) => theme.spacing(1.25),
    listStylePosition: 'inside',
  },
  link: {
    fontSize: (theme) => theme.spacing(0.75),
    fontWeight: 600,
    lineHeight: 1.4,
    color: 'neutral.600',
  },
}

export default styles
