import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../../texts'
import Alert from '../../Alert'
import REDSYS_BANK_CARD_REGISTRATION_ERRORS_ES from './REDSYS_BANK_CARD_REGISTRATION_ERRORS_ES.json'
import IframedInput from './IframedInput'
import { ReactComponent as Calendar } from './Calendar.svg'
import { ReactComponent as Cardholder } from './Cardholder.svg'
import { ReactComponent as LockSimple } from './LockSimple.svg'
import { ReactComponent as Redsys } from './redsys.svg'
import { ReactComponent as VisaMastercard } from './visa-mastercard.svg'
import {
  styles,
  iframedInputStyles,
  iframedInputYearStyles,
  concatIframedButtonStyles,
} from './bankCardForm.styles'
import handleRedsysMessageEvent from './handleRedsysMessageEvent'

// NOTE:
// customCtaConfig styles the iframe
// iconStyles styles the icon which is NOT in the iframe, that's why we use CSS-in-JS
// buttonStyles styles the button which IS in the iframe, that's why we use CSS
const bankCardFormPropTypes = {
  orderData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    merchantCode: PropTypes.string.isRequired,
    merchantTerminal: PropTypes.string.isRequired,
  }),
  error: PropTypes.string,
  onBankCardSubmit: PropTypes.func,
  customCtaConfig: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    iconStyles: PropTypes.object,
    buttonStyles: PropTypes.string,
  }),
}

const BankCardForm = ({
  orderData,
  error,
  onBankCardSubmit,
  customCtaConfig,
}) => {
  if (!Boolean(orderData)) {
    throw new Error('BankCardForm needs orderData')
  }

  const texts = useTexts()
  const defaultCtaConfig = {
    icon: <Cardholder />,
    label: texts.getBankCardSubmitAction(),
    iconStyles: styles.submitIconDefaultPosition,
    buttonStyles: '',
  }
  const ctaConfig = customCtaConfig ?? defaultCtaConfig

  const [fieldsError, setFieldsError] = useState('')

  useEffect(() => {
    const receiveMessage = (ev) => {
      const handleError = (error) => {
        const errorMsg =
          REDSYS_BANK_CARD_REGISTRATION_ERRORS_ES[error] ??
          texts.getErrorBankCardGeneral()

        setFieldsError(errorMsg)
      }

      const { idOper, error } = BankCardForm.handleRedsysMessageEvent(ev)

      if (Boolean(idOper)) {
        onBankCardSubmit(idOper, orderData.id)
      }
      if (Boolean(error)) {
        handleError(error)
      }
    }

    window.addEventListener('message', receiveMessage)
    window.getCardInput('card-number', iframedInputStyles)
    window.getExpirationMonthInput('expiration-month', iframedInputStyles)
    window.getExpirationYearInput('expiration-year', iframedInputYearStyles)
    window.getCVVInput('cvv', iframedInputStyles)
    window.getPayButton(
      'submit-button',
      concatIframedButtonStyles(ctaConfig.buttonStyles),
      ctaConfig.label.replace('€', '&#8364;'),
      orderData.merchantCode,
      orderData.merchantTerminal,
      orderData.id,
    )

    return () => {
      window.removeEventListener('message', receiveMessage)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box id='register-bank-card-form' component='form'>
        <Box sx={styles.bankCard}>
          <IframedInput
            id='card-number'
            label={texts.getBankCardNumberLabel()}
            Icon={<Cardholder />}
          />

          <Box sx={styles.wrapInputs}>
            <Box sx={styles.groupInputs}>
              <IframedInput
                id='expiration-month'
                label={texts.getBankCardMontLabel()}
                Icon={<Calendar />}
              />
              <IframedInput
                id='expiration-year'
                label={texts.getBankCardYearLabel()}
              />
            </Box>
            <IframedInput id='cvv' label='CVV' Icon={<LockSimple />} />
          </Box>

          <input type='hidden' id='token'></input>
          <input type='hidden' id='errorCode'></input>

          <Box sx={styles.logos}>
            <Redsys />
            <VisaMastercard />
          </Box>
        </Box>

        {Boolean(fieldsError) && (
          <Alert variant='error' text={fieldsError} sx={styles.message} />
        )}
        {error !== '' ? (
          <Alert
            variant='error'
            withContactLink
            text={error}
            sx={styles.message}
          />
        ) : null}
      </Box>

      <Box sx={styles.safePayment}>
        <LockSimple />
        <Typography variant='body3'>
          {texts.getIntroBankCardSafePaymentLabel()}
        </Typography>
      </Box>

      <Box sx={styles.submit}>
        <Box aria-hidden sx={{ ...styles.submitIcon, ...ctaConfig.iconStyles }}>
          {ctaConfig.icon}
        </Box>
        <Box id='submit-button' sx={styles.submitIframe} />
      </Box>
    </>
  )
}

BankCardForm.handleRedsysMessageEvent = handleRedsysMessageEvent

BankCardForm.propTypes = bankCardFormPropTypes

export default BankCardForm
