const styles = {
  root: {
    width: (theme) => ({
      xs: `calc(100% - ${theme.spacing(2)})`,
      sm: '100%',
    }),
    mx: 'auto',
    p: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),
    borderRadius: 2.5,
    color: 'neutral.500',
    boxShadow: 4,
    backgroundColor: 'neutral.200',
    position: {
      md: 'relative',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    width: (theme) => `min(${theme.spacing(23)}, 100%)`,
    opacity: 0.6,
  },
  text: {
    color: 'neutral.500',
  },
  wrapImage: {
    position: {
      lg: 'absolute',
    },
    top: (theme) => ({
      lg: theme.spacing(2.5),
    }),
    right: {
      lg: 0,
      xl: '8%',
    },
  },
}

export default styles
