const useTexts = () => {
  const getBalanceTopUpLabel = () => 'Recargar saldo'
  const getRegisterTitleLabel = () => 'Una cuenta, cero suscripciones.'
  const getBalanceTopUpPopularLabel = () => 'POPULAR'
  const getBalanceTopUpAlertTitleLabel = () => 'Sin caducidad.'
  const getBalanceTopUpAlertTextLabel = () =>
    'Puedes usar tu saldo cuando quieras.'
  const getGenericContinueAction = () => 'Continuar'

  return {
    getError: () => 'Se ha producido un error',
    getEmailLabel: () => 'Correo electrónico',
    getPasswordLabel: () => 'Contraseña',
    getNameLabel: () => 'Nombre',
    getFamilyNameLabel: () => 'Apellidos',
    getPhoneLabel: () => 'Teléfono',
    getInfoLinkLabel: () => '+ Info',
    getUnexpectedError: () => 'Algo fue mal, inténtelo de nuevo más tarde.',
    getContactUsOnPersistentError1: () => ' Contacta con nosotros',
    getContactUsOnPersistentError2: () => ' si el problema persiste.',
    getLabelRequiredFields: () => 'Todos los campos son obligatorios',
    getErrorRequired: () => 'Campo obligatorio',
    getErrorBankCardGeneral: () =>
      'Se ha producido un error al iniciar el proceso de registro',
    getErrorBankCardPreauth: () =>
      'Se ha producido un error durante la preautorización del registro. ',
    getErrorEmail: () => 'Debe introducir un correo válido',
    getErrorPhone: () =>
      'Teléfono inválido. Por favor, introduzca las 9 cifras de su número de teléfono',
    getErrorPhoneWithMask: () =>
      'Teléfono inválido. No olvide añadir el prefijo del país, +34 para España.',
    getErrorPassword: () => 'Contraseña inválida',
    getErrorCode: () => 'Código inválido',
    getErrorConditionsAccepted: () => 'Debes aceptar las condiciones legales',
    getSuccessLabel: () => '¡Enhorabuena!',
    getCloseAction: () => 'Cancelar y cerrar',
    getUserDataLabel: () => 'Datos de acceso',
    getUserBankCardLabel: () => 'Datos de tu tarjeta',
    getUserLogoutAction: () => 'Cerrar sesión',
    getConfirmAction: () => 'Sí',
    getCancelAction: () => 'No',
    getGoToHome: () => 'Volver a mi página principal',
    getGoToPurchasesList: () => 'Volver al listado de compras',
    getTopUpBalanceSuccessLabel: () => 'Has recargado tu monedero',
    getOptionalLabel: () => 'Opcional',
    getBankCardNumberLabel: () => 'Número de tarjeta',
    getBankCardMontLabel: () => 'Mes',
    getBankCardYearLabel: () => 'Año',

    getBreadcrumbsHomeLabel: () => 'Página principal',
    getBreadcrumbsPersonalDataEditionLabel: () => 'Actualizar datos de acceso',
    getBreadcrumbsBankCardEditionLabel: () => 'Actualizar datos de la tarjeta',
    getBreadcrumbsBankCardIntroLabel: () => 'Introducir datos de la tarjeta',
    getBreadcrumbsTopUpBalanceLabel: () => 'Recargar monedero',

    getPurchasesTitle: () => 'Tus compras',
    getPurchasesRowsPerPageLabel: () => 'Filas por página',
    getPurchasesDisplayedRowsLabel: (from, to, count) =>
      `${from} • ${to} de ${count}`,
    getPurchasesTableLabel: () => 'Artículos comprados',
    getPurchasesPrevPageLabel: () => 'Ir a la página anterior',
    getPurchasesNextPageLabel: () => 'Ir a la página siguiente',
    getPurchasesEmptyTitleLabel: () =>
      '¡Hay un universo de contenidos esperándote!',
    getPurchasesEmptyTextLabel: () =>
      'Desbloquea las noticias que te interesan en tus medios favoritos.',

    getBonusLabel: () => 'Tienes un bono',
    getBonusLabelLongDesc: () => 'Tienes un bono activo',
    getBonusCardLabel: () =>
      'Tienes acceso libre a cualquier noticia en los medios asociados con Payper 🚀',
    getExpirationLabel: (date, hour) => `Caduca el ${date} a las ${hour}`,

    getBalanceCurrentLabel: () => 'Saldo actual',
    getBalanceTopUpLabel,
    getBalanceTopUpQuantityLabel: () => '¿Cuánto quieres recargar?',
    getBalanceTopUpAction: getBalanceTopUpLabel,
    getBalanceTopUpActionLong: () => 'Quiero recargar el saldo',
    getBalanceTopUpAmountAction: (amount) =>
      `Confirmar el pago de <b>${amount}€</b>`,
    getBalanceTopUpErrorLabel: () =>
      'Se ha producido un error durante la recarga, inténtalo de nuevo más tarde',
    getBalanceTopUpPopularLabel,
    getBalanceTopUpAlertTitleLabel,
    getBalanceTopUpAlertTextLabel,

    getBankCardYourLabel: () => 'Esta es tu tarjeta asociada a Payper',
    getBankCardTitleLabel: getRegisterTitleLabel,
    getBankCardSubtitleLabel: () =>
      'Añade la tarjeta que quieres utilizar para desbloquear artículos.',
    getBankCardTextLabel: () =>
      'No pasaremos ningún cargo a la tarjeta. Recuerda que en Payper solo pagas cuando desbloqueas un artículo.',
    getBankCardBenefitsItemOneLabel: () =>
      'Desbloquea artículos a partir de <span>0,05 €</span>',
    getBankCardBenefitsItemTwoLabel: () =>
      '<span>Sin suscripciones</span> ni permanencias.',
    getBankCardBenefitsItemThreeLabel: () =>
      'Sólo <span>pagas por lo que lees</span>.',
    getBankCardSubmitAction: () => 'Activar tarjeta',
    getBankCardUpdatedLabel: () => 'Actualizada',
    getBankCardOutdatedLabel: () => 'Caducada',
    getBankCardNotUpdatedLabel: () => 'Sin tarjeta',
    getBankCardNotRegisteredLabel: () => 'Sin registrar',
    getBankCardEditAction: () => 'Editar datos de tu tarjeta',
    getBankCardIntroAction: () => 'Introducir tarjeta',
    getBankCardOutdatedMessageLabel: () =>
      'Parece que tu tarjeta está caducada',

    getUpdatePersonalDataTitleLabel: () => 'Vas a cambiar tus datos de acceso',
    getUpdatePersonalDataCurrentPasswordLabel: () => 'Contraseña actual',
    getUpdatePersonalDataNewPasswordLabel: () => 'Nueva contraseña',
    getUpdatePersonalDataUpdateAction: () => 'Actualizar mis datos',
    getUpdatePersonalDataSuccessTitleLabel: () =>
      'Has cambiado tus datos de acceso',
    getUpdatePersonalDataSuccessTextLabel: () =>
      'A partir de ahora, usarás estos datos para acceder a Payper.',
    getUpdatePersonalDataSuccessContinueAction: () =>
      'Acceder con mis nuevos datos',
    getUpdatePersDataInvalidOldPasswordError: () =>
      'Contraseña actual incorrecta',

    getUpdateBankCardTitleLabel: () =>
      'Introduce los datos de tu nueva tarjeta',
    getUpdateBankCardSuccessTitleLabel: () => '¡Has actualizado la tarjeta!',
    getUpdateBankCardSuccessMessageLabel: () =>
      '¡Bien hecho! Ya puedes seguir leyendo tus contenidos favoritos con Payper',
    getIntroBankCardTitleLabel: () => 'Introduce los datos de tu tarjeta',
    getIntroBankCardSuccessTitleLabel: () => '¡Has guardado la tarjeta!',
    getIntroBankCardSuccessMessageLabel: () =>
      '¡Bien hecho! Ya puedes comenzar a leer tus contenidos favoritos con Payper',

    getLoginFormNameLabel: () => 'Correo electrónico',
    getLoginFormPasswordLabel: () => 'Contraseña',
    getLoginFormTitleLabel: () => 'Sólo necesitamos estos datos',
    getLoginFormCtaAction: () => 'Iniciar sesión',
    getTogglePasswordAction: () => 'Mostrar u ocultar contraseña',
    getLoginFormSubmitAction: () => 'Entrar en mi cuenta',
    getPartnerLoginFormSubmitAction: () =>
      'Entrar en mi cuenta y<i>canjear mi bono</i>',
    getLoginFormForgotPasswordAction: () => 'No recuerdo la contraseña',
    getLoginInvalidCredentialsError: () => 'Usuario o contraseña inválidos',
    getLoginInvalidUserError: () =>
      'Su usuario no ha completado correctamente el registro, contacte con Payper para poder solucionarlo.',

    getLogoutTitleLabel: () => '¿Quieres salir de Payper?',

    getIntroBankCardTitle: () => '¿Quieres leer esta noticia?',
    getIntroBankCardPriceLabel: () => 'Esta noticia tiene un coste de ',
    getIntroBankCardDataLabel: () => 'Datos de tu tarjeta',
    getIntroBankCardDataCaption: () =>
      'Registra tu tarjeta, para desbloquear contenido más rápido.',
    getIntroBankCardSafePaymentLabel: () => 'Pago 100% seguro',
    getIntroBankCardCTA: (amount) => `Pagar ${amount}€ por esta noticia`,

    getApplePayTopUpTitle: () => 'Recarga tu saldo y lee con Payper',
    getGoToIntroBankCardAction: () => 'Pagar con tarjeta',
    getApplePayTopUpError: () =>
      'Se ha producido un error durante el proceso de recarga con ApplePay',

    getBalanceTopUpTitle: () => '¿Cuánto quieres recargar?',
    getBalanceTopUpAfterBankCardTitle: () =>
      '¿Sabes que puedes hacerte la vida más facil?',
    getBalanceTopUpDescription: () =>
      'Con una pequeña recarga podrás acceder a muchas más noticias sin tener que introducir tu tarjeta más veces.',

    getTopUpConfirmAction: (highlight) =>
      `Cargar mi tarjeta con <b>${highlight}€</b>`,
    getTopUpSkipAction: () => 'Quizá más adelante',

    getSuccessRegisterTitle: () => '¡Ya eres de Payper!',
    getSuccessRegisterSubtitle: () =>
      'Ahora puedes leer tus noticias preferidas sin suscribirte. Desde 0,05€/noticia.',
    getRegisterCtaLabel: () => 'Acceder a la noticia',
    getSuccessPurchaseTitle: () => '¡Felicidades!',
    getSuccessPurchaseSubtitle: () =>
      'Ya tienes el contenido a tu disposición.',
    getPurchaseErrorTitle: () => 'Ha habido un error',
    getPurchaseErrorTitle2: () => 'Ups.. algo fue mal',
    getPurchaseErrorSubtitle: () =>
      'No se ha podido realizar la compra de la noticia. Te recomendamos que lo intentes de nuevo.',
    getTopUpErrorSubtitle: () =>
      'No se ha podido realizar la recarga solicitada. Te recomendamos que lo intentes de nuevo.',
    getPurchaseTopUpErrorTitle: () => 'Se ha producido un error',
    getPurchaseTopUpErrorSubtitle: () =>
      'No se ha podido realizar la recarga. Te recomendamos que lo intentes de nuevo.',
    getPurchaseErrorRetryAction: () => 'Volver a intentarlo',
    getPurchaseErrorCancelProcessAction: () => 'Volver al periódico',

    getContactFormTitleLabel: () => '¿Tienes dudas o comentarios?',
    getContactFormDescriptionLabel: () =>
      'Al enviar el contenido de este formulario, aceptas la política de privacidad y términos del servicio.',
    getContactFormNameLabel: () => 'Nombre',
    getContactFormSurnameLabel: () => 'Apellidos',
    getContactFormEmailLabel: () => 'Email',
    getContactFormMessageLabel: () => 'Déjanos aquí tu mensaje',
    getContactFormSuccessTitleLabel: () => '¡Mensaje recibido!',
    getContactFormSuccessDescriptionLabel: () =>
      'Nos pondremos en contacto contigo a la mayor brevedad posible.',
    getContactFormTitleError: () => 'Lo sentimos, algo ha salido mal...',
    getContactFormDescriptionError: () =>
      'Por razones técnicas ajenas a nuestra voluntad, no hemos podido enviar tu mensaje.',
    getContactFormSendAction: () => 'Enviar mi mensaje',
    getContactFormSendSuccessAction: () => '¿Quieres contarnos algo más?',

    getHomepageHeroTitleLabel: () => 'La libertad de estar bien informado.',
    getHomepageHeroItemOneLabel: () => 'Una cuenta para todos tus periódicos.',
    getHomepageHeroItemTwoLabel: () =>
      'Pága solo por las noticias que tú decidas leer.',
    getHomepageHeroItemThreeLabel: () =>
      'Desbloquea contenido premium a partir de 0,05 €.',
    getHomepageBenefitsItemOneLabel: () =>
      'Regístrate, sin permanencias ni cuotas.',
    getHomepageBenefitsItemTwoLabel: () =>
      'Rellena tu monedero con el importe que quieras.',
    getHomepageBenefitsItemThreeLabel: () =>
      'Lee contenido premium de nuestra red de periódicos.',
    getHomepageBenefitsTitleLabel: () => 'Cómo funciona Payper',
    getHomepageRegisterAction: () => 'Apuntarme gratis, en 2 minutos',
    getHomepageHightlightsItemOneLabel: () =>
      'Navega en tus periódicos<br />habituales',
    getHomepageHightlightsItemTwoLabel: () =>
      'A partir de 5 céntimos<br />por noticia',
    getHomepageHightlightsItemThreeLabel: () =>
      'Eres libre: tú decides<br />dónde y cuándo',
    getPrivacyPolicyLabel: () => 'Política de privacidad',
    getServiceTermsLabel: () => 'Términos y condiciones',
    getLegalNoticeLabel: () => 'Aviso legal',
    getLegalAgreementLabel1: () => 'He leído y acepto los ',
    getLegalAgreementLabel2: () => ' de Payper.',
    getFormAgreementLabel: () =>
      'Al enviar el contenido de este formulario, aceptas ',
    getLegalAgreementPrivacyPolicyLabel: () => 'Política de Privacidad',
    getLegalAgreementTermsLabel: () => 'Términos y Condiciones',
    getAndLabel: () => ' y la ',
    getLegalAgreementCommunicationsLabel: () =>
      'Acepto recibir comunicaciones comerciales de Payper y los medios asociados.',

    getRegisterResendEmailAction: () => 'Enviar el correo de nuevo',
    getRegisterLoginAction: () => 'Ya tengo cuenta',
    getRegisterContinueAction: () => 'Continuar',
    getRegisterAcceptAllAndContinueAction: () => 'Aceptar todo y continuar',
    getRegisterAcceptLegalAction: () => 'Aceptar condiciones legales',
    getRegisterCtaAction: () => 'Regístrate',
    getRegisterJoinAction: () => 'Únete a Payper',
    getRegisterAction: () => 'Crear una cuenta en Payper',
    getForgottenPasswordAction: () => 'He olvidado mi contraseña',
    getCodeMismatchError: () =>
      'El código introducido no es correcto. Revísalo o pídenos uno nuevo.',
    getExpiredCodeError: () =>
      'El código introducido ha caducado. Pídenos uno nuevo.',
    getRegisterUserAlreadyExistsError: () =>
      'Ya existe una cuenta registrada con este correo',
    getRegisterTitleLabel: getRegisterTitleLabel,
    getRegisterSubtitleLabel: () => 'Crea tu cuenta Payper en 2 minutos',
    getRegisterCodeVerificationLabel: getRegisterTitleLabel,
    getRegisterCodeVerificationSubtitleLabel: () => 'Revisa tu email',
    getRegisterCodeVerificationTextLabel: () =>
      'Por seguridad, te hemos enviado un mensaje con un código de seguridad para confirmar el registro y que puedas acceder a tu cuenta.',
    getRegisterCodeVerificationNoteLabel: () =>
      'Si no ves el correo en tu bandeja de entrada, revisa la carpeta de SPAM.',
    getRegisterCodeVerificationRequired: () =>
      'Debes introducir todos los dígitos.',
    getRegisterCodeVerificationResendSuccedLabel: () =>
      'Se ha enviado un nuevo código, revise su bandeja de entrada',
    getRegisterConditionsLabel: () => 'condiciones',
    getRegisterTermsOfServiceLabel: () => 'términos del servicio',
    getRegisterPasswordRequirementsLabel: () =>
      'Tu contraseña debe ser de al menos 8 caracteres, con una mayúscula, una minúscula y un número',
    getRegisterBankCardError: () =>
      'Se ha producido un error al registrar la tarjeta',

    getRegisterTopUpTitleLabel: getRegisterTitleLabel,
    getRegisterTopUpSubtitleLabel: () =>
      'Ya casi está, ¿quieres hacer tu primera recarga?',
    getRegisterTopUpAction: (highlight) =>
      `Cargar mi tarjeta con <b>${highlight}</b>`,
    getRegisterTopUpSkipAction: () => 'Quizá en otro momento',
    getRegisterMinimumTopUpAction: (amount) =>
      `Quiero pagar solo <b>${amount}</b> por esta noticia`,

    getRegisterSuccessTitleLabel: () => 'Enhorabuena 🎉',
    getRegisterSuccessSubtitleLabel: () => '¡Ya eres de Payper!',
    getRegisterSuccessDescriptionLabel: () =>
      'Ya puedes desbloquear el contenido premium de los periódicos que quieres leer.',
    getRegisterSuccessContinueAction: getGenericContinueAction,
    getRegisterSSOSuccessContinueAction: () => 'Continuar leyendo',

    getForgottenPasswordCaptionLabel: () => 'Restablecer mi contraseña',
    getForgottenPasswordEnterEmailTitleLabel: () =>
      '¿Problemas para iniciar sesión?',
    getForgottenPasswordEnterEmailDescriptionLabel: () =>
      'Ingresa tu correo electrónico y te enviaremos un código de verificación para que recuperes el acceso a tu cuenta.',
    getForgottenPasswordResetCodeLabel: () => 'Código de recuperación',
    getForgottenPasswordEnterEmailAction: () => 'Enviar código',
    getForgottenPasswordSaveNewPasswordAction: () => 'Restablecer contraseña',
    getForgottenPasswordResendCodeAction: () => 'Enviar un nuevo código',

    getForgottenPasswordEnterCodeTitleLabel: () =>
      'Mira tu correo y completa el formulario',
    getForgottenPasswordEnterCodeDescriptionLabel: () =>
      'Añade el código enviado a tu email y escribe una nueva contraseña.',
    getForgottenPasswordEnterCodeAction: () => 'Enviar código',

    getNotFoundErrorTitle: () =>
      'Lo sentimos, parece que no se puede cargar esta página.',
    getNotFoundErrorSubtitle: () =>
      'Vuelve a la tu página principal para seguir disfrutando de tu contenido.',
    getNotFoundErrorCTA: () => 'Volver a la página principal',
    getSomethingWentWrontErrorTitle: () => 'Algo no fue como se esperaba...',
    getSomethingWentWrontErrorSubtitle: (error) =>
      `Se ha producido el siguiente error: \n ${error}. \n Contacte con Payper si el problema persiste.`,
    getUnexpectedErrorTitle: () => 'Ups...  hay un error de conexión',
    getUnexpectedErrorSubtitle: () =>
      'Intenta cargar la página de nuevo para acceder al contenido.',
    getUnexpectedErrorCTA: () => 'Volver a cargar la página',
    getUnexpectedErrorSecondaryButton: () => 'Ir a mi página principal',

    getCookiesTitle: () => 'Política de cookies',
    getCookiesAriaLabel: () => 'Modal de política de cookies',
    getCookiesContent: () =>
      'Utilizamos cookies propias y de terceros para fines analíticos y para poder ofrecerte un mejor servicio. Puedes saber más de nuestra ',
    getCookiesContent2: () => 'política de cookies.',
    getAcceptCTA: () => 'Aceptar',
    getRefuseCTA: () => 'Rechazar',

    getUseGoogleAccountCTA: () => 'Usar mi cuenta Google',
    getUseAppleAccountCTA: () => 'Usar mi cuenta Apple',

    getPayperMarketingLabel: () => 'Servicio ofrecido por ',
    getLoginWidgetHeadingLabel: () =>
      '¡Bienvenido a Payper!<br />La libertad de estar bien informado',
    getLoginWidgetItemOneLabel: () => 'Sin suscripciones.',
    getLoginWidgetItemTwoLabel: () => 'Con Payper, paga sólo por lo que lees.',
    getLoginWidgetItemThreeLabel: () => 'En los medios que te gustan.',
    getLoginWidgetRegisterLabel: () => '¿Aún no tienes una cuenta?',
    getLoginWidgetRegisterTextLabel: () =>
      'Crea tu cuenta en 2 minutos y empieza a disfrutar de tus contenidos favoritos.',
    getLoginWidgetRegisterCtaLabel: () => 'Crea tu cuenta en<i>dos minutos</i>',
    getLoginPartnerRegisterCtaLabel: () =>
      'Crear mi cuenta y<i>canjear mi bono</i>',
    getLoginWidgetSocialLabel: () => 'O identifícate con...',
    getLoginWidgetLoginLabel: () => 'Entra en tu cuenta Payper',

    getPrivacySummaryQuestion1: () => '¿Qué empresa trata tus datos?',
    getPrivacySummaryQuestion2: () =>
      '¿Por qué tratamos los datos que te pedimos?',
    getPrivacySummaryQuestion3: () =>
      '¿Cuál es la legitimación para este tratamiento de tus datos?',
    getPrivacySummaryQuestion4: () =>
      '¿Se van a hacer cesiones o transferencias con tus datos?',
    getPrivacySummaryQuestion5: () => '¿Cuáles son mis derechos?',
    getPrivacySummaryQuestion6: () =>
      '¿Se llevará a cabo un perfilado de los datos?',
    getPrivacySummaryQuestion7: () => '¿Tienes dudas?',

    getPrivacySummaryResponse1: () =>
      'MARTER 878, S.L. (en adelante, “PAYPER”)',
    getPrivacySummaryResponse2: () =>
      'Tratamos tus datos para poder prestarte los servicios de PAYPER y enviarte información sobre nuestros productos y/o servicios, así como para ceder tus datos a empresas del sector de medios de comunicación para que puedan prestarte servicios propios e impactarte con comunicaciones comerciales sobre sus servicios y/o productos.',
    getPrivacySummaryResponse3: () =>
      'Estos datos son necesarios para llevar a cabo la prestación de los servicios que se hayan solicitado a través de la Plataforma, así como el consentimiento que nos hayas otorgado conforme a nuestra Política de Privacidad.',
    getPrivacySummaryResponse4: () =>
      'Tus datos podrán cederse a medios de comunicación para la prestación de servicios propios y el envío de comunicaciones comerciales de los mismos.',
    getPrivacySummaryResponse5: () =>
      'El interesado tiene derecho a ejercitar su derecho de:',
    getPrivacySummaryResponseList5: () => [
      'Acceso.',
      'Rectificación.',
      'Supresión.',
      'Oposición.',
      'Portabilidad de los Datos.',
      'Limitación del Tratamiento.',
      'No ser objeto de decisiones automatizadas individualizadas.',
    ],
    getPrivacySummaryResponse6: () =>
      'Sí, se llevará a cabo un perfilado de tus datos personales por parte de PAYPER. Sin embargo, tienes derecho a oponerte a que tus datos personales sean objeto de un tratamiento basado en la elaboración de perfiles en ciertas circunstancias.',
    getPrivacySummaryResponse7: () =>
      'Tanto si tienes alguna duda o sugerencia como si quieres darte de baja ponte en contacto con nosotros enviando un email a la siguiente dirección: info@bepayper.com',
    getPrivacySummaryLinkLabel7: () => 'Contacta con Payper',
  }
}

export default useTexts
