import { Box, Typography } from '@mui/material'
import { useTexts } from '../../../../../texts'
import { Picture } from '../../../../components'

import imageAvif from './mobile-check.avif'
import imageWebp from './mobile-check.webp'
import imagePng from './mobile-check.png'
import styles from './emptyState.styles'

const EmptyState = () => {
  const texts = useTexts()
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Box sx={styles.info}>
          <Typography variant='h4'>
            {texts.getPurchasesEmptyTitleLabel()}
          </Typography>
          <Typography sx={styles.text}>
            {texts.getPurchasesEmptyTextLabel()}
          </Typography>
        </Box>
        <Box sx={styles.wrapImage}>
          <Picture
            width={516}
            height={332}
            avif={imageAvif}
            webp={imageWebp}
            fallback={imagePng}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default EmptyState
