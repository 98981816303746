import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../../../texts'

import { ReactComponent as Check } from './Check.svg'
import styles from './bankCardStep.styles'

const Benefits = () => {
  const texts = useTexts()

  const ITEMS = [
    texts.getBankCardBenefitsItemOneLabel(),
    texts.getBankCardBenefitsItemTwoLabel(),
    texts.getBankCardBenefitsItemThreeLabel(),
  ]
  return (
    <Box sx={styles.list} component='ul'>
      {ITEMS.map((item, idx) => (
        <Box key={idx} component='li' sx={styles.item}>
          <Box key={idx} sx={styles.icon} aria-hidden>
            <Check />
          </Box>
          <Typography
            sx={styles.itemText}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        </Box>
      ))}
    </Box>
  )
}
export default Benefits
