import { Typography, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { formatNumber, formatShortDate, formatHour } from '../../../../format'
import { useTexts } from '../../../../../texts'
import { User } from '../../../../../business'
import CardWrapper from '../CardWrapper'

import { ReactComponent as Present } from './Present.svg'
import { ReactComponent as Calendar } from './Calendar.svg'
import styles from './bonus.styles'

const BonusCard = () => {
  const texts = useTexts()
  const bonus = User.useActiveBonus()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  if (bonus.isLoading || bonus.error || !Boolean(bonus.data)) return null
  return (
    <CardWrapper
      shrink
      title={
        widerThanTablet ? texts.getBonusLabelLongDesc() : texts.getBonusLabel()
      }
      icon={<Present />}
    >
      <Box sx={styles.card}>
        <Box
          sx={{
            ...styles.info,
            ...(bonus.data.type === 'balance' ? null : styles.infoFree),
          }}
        >
          {bonus.data.type === 'balance' ? (
            <Typography sx={styles.amount}>
              {formatNumber(bonus.data.detail.amount)} <span>€</span>
            </Typography>
          ) : null}
          <Typography sx={styles.description}>
            {texts.getBonusCardLabel()}
          </Typography>
        </Box>
        <Box sx={styles.expiration}>
          {widerThanTablet ? <Calendar /> : null}
          <Typography sx={styles.expirationText}>
            {texts.getExpirationLabel(
              formatShortDate(bonus.data.expiration_date),
              formatHour(bonus.data.expiration_date),
            )}
          </Typography>
        </Box>
      </Box>
    </CardWrapper>
  )
}

export default BonusCard
