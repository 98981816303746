import { Session } from './business'

const ga = {
  event: (eventName, detail) => window.gtag('event', eventName, detail),
}

let ssoData = {}

const initializeDetailWithUserSub = () => {
  const userSub = Session.getUserSub()
  return Boolean(userSub) ? { userSub } : {}
}

const saveDetailDataLocally = ({ detail }) => {
  ssoData = detail
}

document.addEventListener('ssoLoad', saveDetailDataLocally)

document.addEventListener('ssoBankCardRegistration', saveDetailDataLocally)

document.addEventListener('leaveRegister', ({ detail: stepName }) => {
  ga.event('leave_register', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    stepName,
  })
})

document.addEventListener('leaveForgottenPassword', ({ detail: stepName }) => {
  ga.event('leave-forgotten-password', {
    ...ssoData,
    stepName,
  })
})

document.addEventListener('formSubmitted', ({ detail: formData }) => {
  ga.event('form_submitted', {
    ...ssoData,
    ...formData,
  })
})

document.addEventListener(
  'registerConfirmEmailForm',
  ({ detail: formData }) => {
    ga.event('register-confirm-email-form', {
      ...ssoData,
      ...formData,
    })
  },
)

document.addEventListener('registerBankCardForm', ({ detail: formData }) => {
  ga.event('register-bank-card-form', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    ...formData,
  })
})

document.addEventListener('registerTopUpBtn', ({ detail: formData }) => {
  ga.event('register-top-up-btn', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    ...formData,
  })
})

document.addEventListener('registerSkipTopUpBtn', ({ detail: formData }) => {
  ga.event('register-skip-top-up-btn', {
    ...initializeDetailWithUserSub(),
    ...ssoData,
    ...formData,
  })
})
