import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { decode } from '../format'
import { URL_SEARCH_PARAMS_AUTH_KEYS } from './constants'

const useSocialLoginCallback = (redirectionPathOnSuccess) => {
  const [isVerifyingSocialLogin, setIsVerifyingSocialLogin] = useState(true)

  const location = useLocation()

  const urlSearchParams = new URLSearchParams(location.search)
  const error = urlSearchParams.get(
    URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_ERROR,
  )
  const errorDescription =
    urlSearchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_ERROR_DESC) ??
    ''

  if (!error) {
    const code = urlSearchParams.get(
      URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE,
    )

    const encodedState = urlSearchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.STATE)
    const state = decode(encodedState)

    const mergedSearchParams = new URLSearchParams({
      ...state,
      code,
    }).toString()

    window.location = `${redirectionPathOnSuccess}?${mergedSearchParams}`
  } else {
    setIsVerifyingSocialLogin(false)
  }

  return { isVerifyingSocialLogin, errorDescription }
}

export default useSocialLoginCallback
