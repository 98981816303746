import { Link } from 'react-router-dom'
import { Button, Box } from '@mui/material'

import { Chip } from '../../../../components'
import { useTexts } from '../../../../../texts'
import CardWrapper from '../CardWrapper'

import { ReactComponent as Contactless } from './Contactless.svg'
import { ReactComponent as Bag } from './Bag.svg'
import { ReactComponent as Plus } from './Plus.svg'
import styles from './bankCardIntro.styles'

const BankCardIntro = () => {
  const texts = useTexts()

  return (
    <CardWrapper title={texts.getUserBankCardLabel()} icon={<Bag />}>
      <Box sx={styles.card}>
        <Box sx={styles.top}>
          <Box sx={styles.left}>
            <Chip
              text={texts.getBankCardNotRegisteredLabel()}
              variant={'error'}
            />
            <Box sx={styles.icon}>
              <Contactless />
            </Box>
          </Box>
        </Box>

        <Button
          id='dashboard-top-up-btn'
          fullWidth
          color='primary'
          startIcon={<Plus />}
          sx={styles.cta}
          component={Link}
          to='bank-card-introduction'
        >
          {texts.getBankCardIntroAction()}
        </Button>
      </Box>
    </CardWrapper>
  )
}

export default BankCardIntro
