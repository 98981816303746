export const formatNumber = (figure) => {
  const format = (number) =>
    new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2,
      signDisplay: 'never',
    }).format(number.toFixed(2))

  return typeof figure === 'number'
    ? format(figure)
    : typeof figure === 'string'
    ? format(Number(figure))
    : figure
}

export const formatPrice = (number) =>
  new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
    number,
  )

const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)))
}
export const encode = (value) => utf8_to_b64(JSON.stringify(value))

const b64_to_utf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)))
}
export const decode = (value) => JSON.parse(b64_to_utf8(value))

// returned format: dd/MM/yy
export const formatShortDate = (dateStr) =>
  new Intl.DateTimeFormat('es-ES', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  }).format(new Date(dateStr))

// returned format: dd/MM/yyyy
export const formatLongDate = (dateStr) =>
  new Intl.DateTimeFormat('es-ES', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(dateStr))

export const formatHour = (dateStr) =>
  new Intl.DateTimeFormat('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(new Date(dateStr))
