import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'

import { ReactComponent as Logo } from './logo-payper.svg'
import styles from './header.styles'

const ViewPropTypes = {
  renderActions: PropTypes.func.isRequired,
  renderBalanceCta: PropTypes.func.isRequired,
}

const HeaderContainer = ({ renderActions, renderBalanceCta }) => {
  return (
    <Box component='header' sx={styles.root} id='dashboard-header'>
      <Box sx={styles.toolbar}>
        <Box sx={styles.wrapLogo}>
          <Button sx={styles.logo} component={Link} to='/dashboard'>
            <Logo aria-label='Payper logo' />
          </Button>
          <Box sx={styles.wrapCta}>{renderBalanceCta()}</Box>
        </Box>
        {renderActions()}
      </Box>
    </Box>
  )
}

HeaderContainer.propTypes = ViewPropTypes

export default HeaderContainer
