import { backofficeEndpoint } from '../../config'

import { formatLongDate } from '../../App/format'
import fetchWithAuthorization from './fetchWithAuthorization'
import InvalidTokenError from './InvalidTokenError'
import InvalidOldPasswordError from './InvalidOldPasswordError'
import RedsysError from './RedsysError'

export const fetchPurchases = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/article-purchases`, {
    method: 'GET',
    headers: authHeaders,
  })
  if (response.ok) {
    const data = await response.json()

    return data.map((datum) => ({
      ...datum,
      date: formatLongDate(datum.date),
    }))
  }

  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

// export const buyArticle = fetchWithAuthorization(
//   async (authHeaders, article, mediaId) => {
//     const response = await fetch(
//       `${backofficeEndpoint}/user/article-purchases`,
//       {
//         method: 'POST',
//         headers: {
//           ...authHeaders,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ article, mediaId }),
//       },
//     )

//     if (response.ok) {
//       return response.json()
//     }
//     throw new Error(response.error)
//   },
// )

export const changePassword = fetchWithAuthorization(
  async (authHeaders, { oldPassword, newPassword }) => {
    const response = await fetch(`${backofficeEndpoint}/user/change-password`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    })

    if (response.ok && response.status === 200) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 400) {
      const error = await response.json()
      if (error.code === 'NotAuthorizedException') {
        throw new InvalidOldPasswordError()
      }
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    throw new Error('Something went wrong')
  },
)

const checkIsBankCardExpired = ({
  month: expiryDateMonth,
  year: expiryDateYear,
}) =>
  expiryDateYear < new Date().getFullYear() ||
  (expiryDateYear === new Date().getFullYear() &&
    expiryDateMonth - 1 < new Date().getMonth())

export const fetchBankCard = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/bank-card`, {
    method: 'GET',
    headers: authHeaders,
  })
  if (response.ok) {
    const data = await response.json()

    const formattedData = {
      ...data,
      expiryDate: `${data.expiryDate.month
        .toString()
        .padStart(2, '0')}/${data.expiryDate.year.toString().slice(2)}`,
      isExpired: checkIsBankCardExpired(data.expiryDate),
    }
    return formattedData
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const fetchBalance = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/wallet-balance`, {
    method: 'GET',
    headers: authHeaders,
  })

  if (response.ok) {
    return await response.text()
  }
  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const fetchActiveBonus = fetchWithAuthorization(async (authHeaders) => {
  const response = await fetch(`${backofficeEndpoint}/user/active-bonus`, {
    method: 'GET',
    headers: authHeaders,
  })

  if (response.ok) {
    return await response.json()
  }

  if (!response.ok && response.status === 404) {
    return null
  }

  if (!response.ok && response.status === 401) {
    throw new InvalidTokenError(response.statusText)
  }
})

export const updateAccountBalance = fetchWithAuthorization(
  async (authHeaders, amount, applePayPaymentData) => {
    const body = { amount }
    if (Boolean(applePayPaymentData)) {
      body.applePayPaymentData = applePayPaymentData
    }

    const response = await fetch(`${backofficeEndpoint}/user/balance`, {
      method: 'PUT',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (response.ok) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    if (!response.ok && response.status === 400) {
      const bodyResponse = await response.json()
      if (bodyResponse.code === 'PaymentServiceError') {
        throw new RedsysError(bodyResponse.error)
      }
    }

    throw new Error('Something went wrong')
  },
)

export const createBonus = fetchWithAuthorization(
  async (authHeaders, bonusToken, partnerId) => {
    const response = await fetch(`${backofficeEndpoint}/user/bonus`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ partnerId, bonusToken }),
    })

    if (response.ok) {
      return Promise.resolve()
    }
  },
)

export const createPayperBonus = fetchWithAuthorization(
  async (authHeaders, bonusToken) => {
    const response = await fetch(`${backofficeEndpoint}/user/payper-bonus`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ bonusToken }),
    })

    if (response.ok) {
      return Promise.resolve()
    }
  },
)

export const sendEmail = fetchWithAuthorization(
  async (authHeaders, emailData) => {
    const response = await fetch(`${backofficeEndpoint}/user/send-email`, {
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    })

    if (response.ok && response.status === 204) {
      return Promise.resolve()
    }
    if (!response.ok && response.status === 401) {
      throw new InvalidTokenError(response.statusText)
    }
    if (!response.ok) {
      throw new Error('email could not be sent')
    }
  },
)

export const validateApplePayMerchant = fetchWithAuthorization(
  async (authHeaders, validationUrl) => {
    const response = await fetch(
      `${backofficeEndpoint}/user/apple-pay/merchant-validation`,
      {
        method: 'POST',
        headers: {
          ...authHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ validationUrl }),
      },
    )

    if (response.ok) {
      return response.json()
    }
    throw new Error(
      `Error fetching ApplePay merchant session: ${response.error}`,
    )
  },
)
