import { useLocation } from 'react-router-dom'

import { Auth } from '../../../business'
import { encode } from '../../format'
import useSSO from '../../Pages/useSSO'
import useSSOPartner from '../../Pages/useSSOPartner'

import View from './View'

const getCurrentParamsObject = (location) => {
  const currentSearchParams = new URLSearchParams(location.search)
  return Object.fromEntries(currentSearchParams.entries())
}

export const SocialLoginButtons = (props) => {
  const currentParams = getCurrentParamsObject(useLocation())
  const stateParam = encode(currentParams)

  return (
    <View
      {...props}
      appleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE,
        isSSO: false,
        stateParam,
      })}
      googleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE,
        isSSO: false,
        stateParam,
      })}
    />
  )
}

export const SSOSocialLoginButtons = (props) => {
  const sso = useSSO()

  const currentParams = getCurrentParamsObject(useLocation())
  const ssoStateParam = sso.getSSOThirdPartyIdpStateParam(currentParams)

  return (
    <View
      {...props}
      appleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE,
        isSSO: true,
        stateParam: ssoStateParam,
      })}
      googleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE,
        isSSO: true,
        stateParam: ssoStateParam,
      })}
    />
  )
}

export const SSOPartnerSocialLoginButtons = (props) => {
  const ssoPartner = useSSOPartner()

  const currentParams = getCurrentParamsObject(useLocation())
  const ssoPartnerStateParam =
    ssoPartner.getSSOThirdPartyIdpStateParam(currentParams)

  return (
    <View
      {...props}
      appleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE,
        isSSO: true,
        stateParam: ssoPartnerStateParam,
      })}
      googleHref={Auth.getThirdPartyIdpAuthorizedEndpoint({
        identityProvider: Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE,
        isSSO: true,
        stateParam: ssoPartnerStateParam,
      })}
    />
  )
}
