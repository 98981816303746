import { URL_SEARCH_PARAMS_AUTH_KEYS } from '../../App/Pages/constants'

const addTruthySearchParamToObject =
  (searchParams) => (obj) => (propKey, searchParamKey) => {
    if (Boolean(searchParams.get(searchParamKey))) {
      obj[propKey] = searchParams.get(searchParamKey)
    }
  }

const getConvertionParams = (location) => {
  const searchParams = new URLSearchParams(location.search)
  const addTruthySearchParamTo = addTruthySearchParamToObject(searchParams)

  const convertionParams = {}

  const addPropertyToConvertionParams = addTruthySearchParamTo(convertionParams)
  addPropertyToConvertionParams('mediaId', URL_SEARCH_PARAMS_AUTH_KEYS.MEDIA_ID)
  addPropertyToConvertionParams(
    'partnerId',
    URL_SEARCH_PARAMS_AUTH_KEYS.PARTNER_ID,
  )

  const utm = {}
  const addPropertyToUtmParams = addTruthySearchParamTo(utm)
  addPropertyToUtmParams('source', URL_SEARCH_PARAMS_AUTH_KEYS.UTM_SOURCE)
  addPropertyToUtmParams('medium', URL_SEARCH_PARAMS_AUTH_KEYS.UTM_MEDIUM)
  addPropertyToUtmParams('campaign', URL_SEARCH_PARAMS_AUTH_KEYS.UTM_CAMPAIGN)
  addPropertyToUtmParams('id', URL_SEARCH_PARAMS_AUTH_KEYS.UTM_ID)
  addPropertyToUtmParams('term', URL_SEARCH_PARAMS_AUTH_KEYS.UTM_TERM)
  addPropertyToUtmParams('content', URL_SEARCH_PARAMS_AUTH_KEYS.UTM_CONTENT)
  if (Boolean(Object.values(utm).length)) {
    convertionParams.utm = utm
  }

  return convertionParams
}

export default getConvertionParams
