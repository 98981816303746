import { Box } from '@mui/material'

import styles from './decorator.styles.js'

const Decorator = () => (
  <Box sx={styles.root}>
    <svg
      width='28'
      height='1'
      viewBox='0 0 28 1'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        x1='0.5'
        y1='0.5'
        x2='27.5'
        y2='0.5'
        stroke='#969594'
        strokeDasharray='2 2'
      />
    </svg>
  </Box>
)

export default Decorator
