import { useState } from 'react'

import { User } from '../../../../../business'
import { useTexts } from '../../../../../texts'

import View from './View'

const TopUpStep = ({ onContinue }) => {
  const texts = useTexts()

  const [isRequesting, setIsRequesting] = useState(false)
  const [messageErrorOnRequest, setMessageErrorOnRequest] = useState('')

  const topUpAmount = async (amount, onSuccess, onError) => {
    setIsRequesting(true)

    try {
      await User.updateAccountBalance(amount)
      document.dispatchEvent(
        new CustomEvent('registerTopUpBtn', {
          detail: {
            formId: 'register-top-up-btn',
            status: 'success',
            amount,
          },
        }),
      )

      onSuccess({ amount })
    } catch (error) {
      let errorMessage = texts.getUnexpectedError()

      if (error instanceof User.RedsysError) {
        const redsysErrorMessage = User.getRedsysDSResponseMessageTranslation(
          error.message,
        )
        if (Boolean(redsysErrorMessage)) errorMessage = redsysErrorMessage
      }

      document.dispatchEvent(
        new CustomEvent('registerTopUpBtn', {
          detail: {
            formId: 'register-top-up-btn',
            status: 'error',
            formError: errorMessage,
          },
        }),
      )

      setMessageErrorOnRequest(errorMessage)
    }
    setIsRequesting(false)
  }

  const topUpSelectedAmount = async (event) => {
    event.preventDefault()
    setIsRequesting(true)

    const amount = parseInt(
      Object.fromEntries(new FormData(event.target)).topUpAmount,
      10,
    )
    await topUpAmount(amount, onContinue, () => {})
  }

  const handleSkipTopUp = (args) => {
    document.dispatchEvent(
      new CustomEvent('registerSkipTopUpBtn', {
        detail: {
          formId: 'register-skip-top-up-btn',
          status: 'success',
        },
      }),
    )
    onContinue(args)
  }

  return (
    <View
      onSubmit={topUpSelectedAmount}
      isRequesting={isRequesting}
      messageErrorOnRequest={messageErrorOnRequest}
      onSkipTopUp={handleSkipTopUp}
    />
  )
}

export default TopUpStep
