import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts'

import {
  Alert,
  Decorator,
  FormSkinWrapper,
  TextField,
} from '../../../components'

import { ReactComponent as LockSimple } from './LockSimple.svg'
import { ReactComponent as EnvelopeSimple } from './EnvelopeSimple.svg'
import { ReactComponent as X } from './X.svg'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as EyeClosed } from './EyeClosed.svg'
import { ReactComponent as ArrowRight } from './ArrowRight.svg'
import styles from './login.styles'

const loginViewProptypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  formError: PropTypes.string,
  fieldErrors: PropTypes.shape({
    user: PropTypes.string,
    password: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onGoToRegister: PropTypes.func,
  onGoToForgottenPassword: PropTypes.func,
  renderSocialLogin: PropTypes.func,
}

const LoginView = ({
  formId,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  onCancel,
  onGoToRegister,
  onGoToForgottenPassword,
  renderSocialLogin,
}) => {
  const texts = useTexts()

  const [showPassword, setShowPassword] = useState(false)

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Box sx={styles.header}>
          <Typography component='h2' sx={styles.heading}>
            {texts.getLoginFormTitleLabel()}
          </Typography>
          <Box sx={styles.closeButton}>
            <IconButton aria-label={texts.getCloseAction()} onClick={onCancel}>
              <X />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.body}>
          <form id={formId} onSubmit={onSubmit} noValidate>
            <FormSkinWrapper skin='dark' sx={styles.wrapFields}>
              <TextField
                id='user'
                name='user'
                label={texts.getLoginFormNameLabel()}
                type='email'
                autoComplete='email'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EnvelopeSimple />
                    </InputAdornment>
                  ),
                }}
                helperText={fieldErrors.user}
                error={Boolean(fieldErrors.user)}
                inputProps={{ inputMode: 'email' }}
                required
              />
              <TextField
                id='password'
                name='password'
                label={texts.getLoginFormPasswordLabel()}
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                helperText={fieldErrors.password}
                error={Boolean(fieldErrors.password)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LockSimple />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      <IconButton aria-label={texts.getTogglePasswordAction()}>
                        {showPassword ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              {Boolean(formError) ? (
                <Alert variant='error' text={formError} />
              ) : null}
              <Box sx={styles.actions}>
                <LoadingButton
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  endIcon={<ArrowRight />}
                  loadingIndicator={<CircularProgress size={16} />}
                >
                  {texts.getLoginFormSubmitAction()}
                </LoadingButton>
                <Button
                  id='forgotten-password-btn'
                  size='small'
                  variant='text'
                  color='secondary'
                  onClick={onGoToForgottenPassword}
                  fullWidth
                >
                  {texts.getForgottenPasswordAction()}
                </Button>
              </Box>
            </FormSkinWrapper>
          </form>
          <Decorator />
          {renderSocialLogin()}
          <Box sx={styles.lastAction}>
            <Button
              size='small'
              variant='text'
              color='secondary'
              onClick={onGoToRegister}
              fullWidth
            >
              {texts.getRegisterJoinAction()}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

LoginView.propTypes = loginViewProptypes

export default LoginView
