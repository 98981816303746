import { CircularProgress, Box, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/material'

import { User } from '../../../../business'
import { useTexts } from '../../../../texts'
import theme from '../../../../theme'

import { ReactComponent as Bag } from './Bag.svg'
import EmptyState from './EmptyState'
import Table from './Table'
import {
  PURCHASE_TABLE_HEAD_DATA,
  INITIAL_ROWS_PER_PAGE,
  PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS,
} from './constants'
import styles from './purchases.styles'

const useIsMobile = () => !useMediaQuery(theme.breakpoints.up('md'))

const PurchasesSection = () => {
  const texts = useTexts()
  const isMobile = useIsMobile()

  const purchases = User.usePurchases()

  const handleScrollTopTable = () => {
    if (isMobile) {
      const purchasesSection = document.getElementById('purchases-section')
      purchasesSection.scrollIntoView(true)
      const headerHeight = document
        .getElementById('dashboard-header')
        .getBoundingClientRect().height
      window.scrollBy({ top: -(headerHeight + 30) })
    }
  }

  return (
    <Box component='section' sx={styles.root} id='purchases-section'>
      {purchases.isLoading ? (
        <CircularProgress />
      ) : purchases.error ? (
        <Typography>{texts.getError()}</Typography>
      ) : (
        <Box sx={styles.content}>
          <Box component='header'>
            <Typography component='h2' sx={styles.header}>
              <Box sx={styles.icon}>
                <Bag />
              </Box>
              <Typography component='span' sx={styles.title}>
                {texts.getPurchasesTitle()}
              </Typography>
            </Typography>
          </Box>

          {purchases.data.length === 0 ? (
            <EmptyState />
          ) : (
            <Table
              headData={PURCHASE_TABLE_HEAD_DATA}
              bodyData={purchases.data}
              rowsPerPageOptions={PURCHASE_TABLE_ROWS_PER_PAGE_OPTIONS}
              initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
              changeTableConfig={handleScrollTopTable}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default PurchasesSection
