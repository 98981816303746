import theme from '../../../theme'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    pt: {
      xs: 2.5,
      md: 3.5,
    },
    px: {
      xs: 1.25,
      md: 3,
    },
    pb: {
      xs: 4,
      md: 5,
    },
  },
  title: {
    ...theme.typography.h5,
    mb: 2,
    color: 'neutral.700',
  },
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
}

export default styles
