export const URL_SEARCH_PARAMS_AUTH_KEYS = {
  MEDIA_ID: 'media_id',
  MEDIA_ARTICLE: 'article',
  MEDIA_REDIRECT_URI: 'redirect_uri',
  MEDIA_BONUS: 'bonus',
  PARTNER_ID: 'partner_id',
  PARTNER_BONUS: 'bonus',
  PARTNER_REDIRECT_URI: 'redirect_uri',
  THIRD_PARTY_CODE: 'code',
  THIRD_PARTY_ERROR: 'error',
  THIRD_PARTY_ERROR_DESC: 'error_description',
  STATE: 'state',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_ID: 'utm_id',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
}

export const URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS = {
  REFRESH_TOKEN: 'p_token',
  EMAIL: 'p_email',
  STATE: 'p_state',
  AUTO_PURCHASE_FLAG: 'p_purchase',
  ACCESS_TOKEN: 'payperAccessToken',
}
