import { Box, Button } from '@mui/material'

import { useTexts } from '../../../../../texts'
import Picture from '../../../Picture'
import imageAvif from './mobile-check.png'
import imageWebp from './mobile-check.webp'
import imagePng from './mobile-check.png'
import styles from './successStep.styles.js'

const SuccessStep = ({ onContinue, ctaLabel }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Box sx={styles.wrapPicture}>
        <Picture
          width={228}
          height={216}
          avif={imageAvif}
          webp={imageWebp}
          fallback={imagePng}
        />
      </Box>
      <Button
        id='register-success-btn'
        fullWidth
        color='secondary'
        size='small'
        onClick={onContinue}
      >
        {ctaLabel ?? texts.getAcceptCTA()}
      </Button>
    </Box>
  )
}

export default SuccessStep
