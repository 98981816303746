import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  Link,
  TablePagination,
  Pagination,
  PaginationItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Session } from '../../../../business'
import { useTexts } from '../../../../texts'

import { ReactComponent as CaretRight } from './CaretRight.svg'
import { ReactComponent as CaretDoubleRight } from './CaretDoubleRight.svg'
import { ReactComponent as CaretLeft } from './CaretLeft.svg'
import { ReactComponent as CaretDoubleLeft } from './CaretDoubleLeft.svg'
import { ReactComponent as CaretSelectDown } from './CaretSelectDown.svg'
import styles from './purchases.styles'

const TablePaginationPropTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const TablePaginationMobileActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const texts = useTexts()

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box sx={styles.tablePaginationActions}>
      <IconButton
        disabled={page === 0}
        onClick={handleBackButtonClick}
        aria-label={texts.getPurchasesPrevPageLabel()}
      >
        <CaretLeft />
      </IconButton>
      <IconButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
        aria-label={texts.getPurchasesNextPageLabel()}
      >
        <CaretRight />
      </IconButton>
    </Box>
  )
}

TablePaginationMobileActions.propTypes = TablePaginationPropTypes

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const numberOfPages = Math.ceil(count / rowsPerPage)

  //MUI Pagination is 1-indexed while MUI TablePagination is 0-indexed
  const handleChangePageFromPagination = (event, value) =>
    onPageChange(event, value - 1)

  return (
    <Pagination
      count={numberOfPages}
      //MUI Pagination is 1-indexed while MUI TablePagination is 0-indexed
      page={page + 1}
      onChange={handleChangePageFromPagination}
      siblingCount={1}
      boundaryCount={1}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          components={{
            first: CaretDoubleLeft,
            previous: CaretLeft,
            next: CaretRight,
            last: CaretDoubleRight,
          }}
          {...item}
        />
      )}
    />
  )
}

TablePaginationActions.propTypes = TablePaginationPropTypes

const TablePropTypes = {
  headData: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.number,
      isSortable: PropTypes.bool,
    }),
  ),
  bodyData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      mediaProvider: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }),
  ),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialRowsPerPage: PropTypes.number,
  changeTableConfig: PropTypes.func,
}

const Table = ({
  headData,
  bodyData,
  rowsPerPageOptions,
  initialRowsPerPage,
  changeTableConfig,
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const isWide = useMediaQuery(theme.breakpoints.up('lg'))

  const texts = useTexts()
  const [rowsPerPage, setRowsPerPage] = useState(
    initialRowsPerPage ?? rowsPerPageOptions[0],
  )

  const [page, setPage] = useState(0)
  const startIndex = page * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const tableData =
    bodyData.length === 0 ? [] : bodyData.slice(startIndex, endIndex)

  const openArticleLink = (url) => {
    const urlWithSsoParams = Session.appendAccessParamsToURL(url)
    window.open(`${urlWithSsoParams}`, '_blank', { rel: 'noreferrer noopener' })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    changeTableConfig()
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    changeTableConfig()
  }

  return (
    <Box role='table'>
      <Box role='rowgroup' aria-label={texts.getPurchasesTableLabel()}>
        <Box role='row' sx={styles.tableHeaderRow}>
          {headData.map((columnData) => (
            <Box
              key={columnData.field}
              role='columnheader'
              sx={styles.tableHeaderCell}
              data-field={columnData.field}
            >
              {columnData.label}
            </Box>
          ))}
        </Box>

        <Box role='rowgroup'>
          {tableData.map((rowData) => (
            <Box role='row' key={rowData.id} sx={styles.tableBodyRow}>
              {headData.map((columnData) => (
                <Box
                  key={columnData.field}
                  role='cell'
                  sx={styles.tableBodyCell}
                  data-field={columnData.field}
                  title={
                    columnData.field === 'mediaProvider'
                      ? rowData[columnData.field]
                      : null
                  }
                >
                  {columnData.field === 'headline' && rowData.url ? (
                    <Link
                      sx={styles.tableLink}
                      onClick={() => openArticleLink(rowData.url)}
                    >
                      {rowData[columnData.field]}
                    </Link>
                  ) : columnData.field === 'price' ? (
                    rowData[columnData.field] + ' €'
                  ) : (
                    rowData[columnData.field]
                  )}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <TablePagination
        page={page}
        count={bodyData.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={isSmall ? texts.getPurchasesRowsPerPageLabel() : null}
        labelDisplayedRows={({ from, to, count }) =>
          texts.getPurchasesDisplayedRowsLabel(from, to, count)
        }
        ActionsComponent={
          isWide ? TablePaginationActions : TablePaginationMobileActions
        }
        SelectProps={{
          inputProps: {
            'aria-label': texts.getPurchasesRowsPerPageLabel(),
          },
          IconComponent: CaretSelectDown,
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

Table.propTypes = TablePropTypes

export default Table
