const styles = {
  cookiesLayer: {
    width: (theme) => ({
      xs: '100%',
      md: theme.spacing(28.75),
    }),
    borderRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(1.25),
    }),
    position: 'fixed',
    right: (theme) => ({
      xs: 0,
      md: theme.spacing(2),
    }),
    bottom: (theme) => ({
      xs: 0,
      md: theme.spacing(2),
    }),
    boxShadow: 1,
    opacity: 0,
    transform: 'translateY(100%)',
    transition: 'opacity 1.4s, transform 0.6s cubic-bezier(0, 0.6, 0.4, 1)',
    zIndex: 10,
  },
  isVisible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  content: {
    pt: {
      xs: 1.5,
      md: 2.5,
    },
    px: {
      xs: 1.25,
      md: 2,
    },
    pb: {
      xs: 1.25,
      md: 2,
    },
    borderTopRightRadius: (theme) => ({ xs: 0, md: theme.spacing(1.25) }),
    borderTopLeftRadius: (theme) => ({ xs: 0, md: theme.spacing(1.25) }),
    backgroundColor: 'neutral.100',
  },
  title: {
    mb: 1,
    fontSize: (theme) => theme.spacing(1),
    fontWeight: 600,
    color: 'neutral.700',
  },
  text: {
    color: 'neutral.600',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    p: {
      xs: 1.25,
      md: 2,
    },
    borderBottomRightRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(1.25),
    }),
    borderBottomLeftRadius: (theme) => ({
      xs: 0,
      md: theme.spacing(1.25),
    }),
    backgroundColor: 'neutral.200',
  },
}

export default styles
