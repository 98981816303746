import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import Picture from '../Picture'
import styles from './feedback.styles'

const feedbackPropTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  secondaryButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  picture: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    source: PropTypes.shape({
      avif: PropTypes.node.isRequired,
      webp: PropTypes.node.isRequired,
      fallback: PropTypes.node.isRequired,
    }).isRequired,
    styles: PropTypes.object,
  }),
  isAuthenticated: PropTypes.bool,
}

const Feedback = ({
  isAuthenticated,
  title,
  text,
  button,
  secondaryButton,
  picture,
}) => {
  const pictureStyles = picture?.styles ?? {}
  return (
    <Box
      aria-labelledby='feedback-title'
      sx={{
        ...styles.root,
        ...(isAuthenticated ? styles.isAuthenticated : styles.notAuthenticated),
      }}
    >
      <Box sx={styles.content}>
        <Typography id='feedback-title' component='h1' sx={styles.title}>
          {title}
        </Typography>
        <Typography sx={styles.text}>{text}</Typography>
        <Box sx={styles.buttons}>
          <Button
            fullWidth
            color='secondary'
            component={Link}
            to={button.to}
            size='small'
          >
            {button.text}
          </Button>
          {secondaryButton ? (
            <Button
              fullWidth
              sx={styles.secondaryButton}
              component={Link}
              to={secondaryButton.to}
              size='small'
            >
              {secondaryButton.text}
            </Button>
          ) : null}
        </Box>
        {Boolean(picture) && (
          <Box sx={{ ...styles.wrapImage, ...pictureStyles }}>
            <Picture
              width={picture.width}
              height={picture.height}
              {...picture.source}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

Feedback.propTypes = feedbackPropTypes

export default Feedback
