import { useForm, composeValidators, validators } from '../../forms'
import { useTexts } from '../../texts'
import { isValidPassword } from '../validate'
import { changePassword } from './service'
import InvalidOldPasswordError from './InvalidOldPasswordError'

const usePersonalDataEditionForm = (formId) => {
  const texts = useTexts()

  const FORM_VALIDATION_CONFIG = {
    oldPassword: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
    newPassword: composeValidators([
      [validators.isFilled, texts.getErrorRequired()],
      [isValidPassword, texts.getErrorPassword()],
    ]),
  }

  const submitUpdatePersonalData = async (formValues) => {
    try {
      await changePassword(formValues)
    } catch (error) {
      if (error instanceof InvalidOldPasswordError) {
        throw new Error(texts.getUpdatePersDataInvalidOldPasswordError())
      }
      throw new Error(texts.getUnexpectedError())
    }
  }

  return useForm(formId, submitUpdatePersonalData, FORM_VALIDATION_CONFIG)
}

export default usePersonalDataEditionForm
