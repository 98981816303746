import { Box } from '@mui/material'

import Benefits from './Benefits'
import BankCardRegistration from '../../../BankCardRegistration'

import styles from './bankCardStep.styles'

const BankCardStep = ({ onContinue, sub }) => {
  const handleSuccessBankCard = () => {
    document.dispatchEvent(
      new CustomEvent('registerBankCardForm', {
        detail: {
          formId: 'register-bank-card-form',
          status: 'success',
        },
      }),
    )
    onContinue()
  }

  const handleDispatchEventOnError = (errorMessage) => {
    document.dispatchEvent(
      new CustomEvent('registerBankCardForm', {
        detail: {
          formId: 'register-bank-card-form',
          status: 'error',
          formError: errorMessage,
        },
      }),
    )
  }

  return (
    <Box>
      <Box sx={styles.bg}>
        <Benefits />
        <BankCardRegistration
          onSuccess={handleSuccessBankCard}
          onError={handleDispatchEventOnError}
          sub={sub}
        />
      </Box>
    </Box>
  )
}

export default BankCardStep
