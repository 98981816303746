const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: (theme) => theme.spacing(12.5),
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    backgroundColor: 'success.lightest',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      xs: 'center',
      md: 'stretch',
    },
    rowGap: 0.5,
    py: {
      xs: 1.25,
      md: 1.5,
    },
    px: 1.5,
    borderTopLeftRadius: (theme) => theme.spacing(1.25),
    borderTopRightRadius: (theme) => theme.spacing(1.25),
  },
  infoFree: {
    pt: {
      xs: 2.25,
      md: 2.5,
    },
  },
  amount: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(2),
    }),
    lineHeight: 1.1,
    letterSpacing: '0.03em',
    color: 'neutral.700',

    '& > span': {
      fontFamily: 'Inter',
      fontSize: (theme) => theme.spacing(1.25),
      color: 'success.dark',
    },
  },
  description: {
    pr: {
      md: 1.5,
    },
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.875),
    }),
    fontWeight: {
      xs: 500,
      md: 600,
    },
    textAlign: {
      xs: 'center',
      md: 'left',
    },
    color: 'neutral.500',
  },
  expiration: {
    display: 'flex',
    justifyContent: {
      xs: 'center',
      md: 'flex-start',
    },
    alignItems: 'center',
    columnGap: 0.625,
    px: 1.5,
    pb: 1.5,
    color: 'success.dark',

    '& > svg': {
      fontSize: (theme) => theme.spacing(1.25),
      color: 'success.main',
    },
  },
  expirationText: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.625),
      md: theme.spacing(0.75),
    }),
    fontWeight: 500,
  },
}

export default styles
