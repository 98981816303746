import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import { CircularProgress } from '../../components'
import WithActiveCard from './WithActiveCard'
import ErrorMessage from './ErrorMessage'
import styles from './apple-pay-test.styles'

const MERCHANT_IDENTIFIER =
  process.env.REACT_APP_REDSYS_APPLE_PAY_MERCHANT_IDENTIFIER

const APPLE_PAY_STATUS = {
  LOADING: Symbol('loading'),
  NOT_AVAILABLE: Symbol('not_available'),
  NOT_SUPPORTED: Symbol('not_apple_pay_supported'),
  NO_ACTIVE_CARD: Symbol('no_active_card'),
  ACTIVE_CARD: Symbol('active_card'),
  ERROR: Symbol('error'),
}

const NotAvailable = () => (
  <>
    <p>This feature is not available in this device.</p>
    <p>Check this page from an Apple device.</p>
  </>
)

const NotSupported = () => (
  <p>Your device doesn't support making payments with Apple Pay.</p>
)

const WithoutActiveCard = () => (
  <p>You must provision a card for use with Apple Pay on the device.</p>
)

const ApplePayTest = () => {
  const [status, setStatus] = useState(APPLE_PAY_STATUS.LOADING)
  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      const checkApplePayVersionSupport = () => {
        const versions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
        versions.map(async (version) => {
          const isSupported = await window.ApplePaySession.supportsVersion(
            version,
          )
          console.log(`Version ${version} is supported: ${isSupported}`)
        })
      }

      try {
        if (Boolean(window.ApplePaySession)) {
          await checkApplePayVersionSupport()
          const canMakePaymentsResp =
            await window.ApplePaySession.canMakePayments(MERCHANT_IDENTIFIER)
          const canMakePaymentsWithActiveCardResp =
            await window.ApplePaySession.canMakePaymentsWithActiveCard(
              MERCHANT_IDENTIFIER,
            )

          if (!canMakePaymentsResp) {
            setStatus(APPLE_PAY_STATUS.NOT_SUPPORTED)
          } else if (!canMakePaymentsWithActiveCardResp) {
            setStatus(APPLE_PAY_STATUS.NO_ACTIVE_CARD)
          } else {
            setStatus(APPLE_PAY_STATUS.ACTIVE_CARD)
          }

          console.log({
            canMakePaymentsResp,
            canMakePaymentsWithActiveCardResp,
          })
        } else {
          setStatus(APPLE_PAY_STATUS.NOT_AVAILABLE)
        }
      } catch (error) {
        setError(`${error.name}: ${error.message}`)
        setStatus(APPLE_PAY_STATUS.ERROR)
      }
    })()
  })

  if (status === APPLE_PAY_STATUS.LOADING)
    return <CircularProgress height='80vh' />

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <h1 style={styles.title}>Pay in Payper through Apple Pay</h1>
        {status === APPLE_PAY_STATUS.NOT_AVAILABLE ? (
          <NotAvailable />
        ) : status === APPLE_PAY_STATUS.NOT_SUPPORTED ? (
          <NotSupported />
        ) : status === APPLE_PAY_STATUS.NO_ACTIVE_CARD ? (
          <WithoutActiveCard />
        ) : status === APPLE_PAY_STATUS.ACTIVE_CARD ? (
          <WithActiveCard />
        ) : status === APPLE_PAY_STATUS.ERROR ? (
          <ErrorMessage error={error} />
        ) : null}
      </Box>
    </Box>
  )
}

export default ApplePayTest
