import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { useTexts } from '../../../texts'
import { ReactComponent as GoogleLogo } from './GoogleLogo.svg'
import { ReactComponent as AppleLogo } from './AppleLogo.svg'
import styles from './socialLogin.styles'

const socialLoginButtonsViewPropTypes = {
  skin: PropTypes.oneOf(['dark', 'light']),
  appleHref: PropTypes.string.isRequired,
  googleHref: PropTypes.string.isRequired,
}

const SocialLoginButtonsView = ({ skin = 'light', appleHref, googleHref }) => {
  const texts = useTexts()

  const buttonVariant = skin === 'dark' ? 'appleLight' : 'appleDark'

  return (
    <Box sx={styles.root}>
      <Button
        id='register-google-btn'
        variant='google'
        component='a'
        href={googleHref}
        startIcon={<GoogleLogo />}
        size='small'
        sx={styles.cta}
      >
        {texts.getUseGoogleAccountCTA()}
      </Button>
      <Button
        id='register-apple-btn'
        variant={buttonVariant}
        component='a'
        href={appleHref}
        startIcon={<AppleLogo />}
        size='small'
        sx={styles.cta}
      >
        {texts.getUseAppleAccountCTA()}
      </Button>
    </Box>
  )
}

SocialLoginButtonsView.propTypes = socialLoginButtonsViewPropTypes

export default SocialLoginButtonsView
