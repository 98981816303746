const styles = {
  cta: {
    '&.MuiButton-root': {
      display: 'inline-flex',
      alignItems: 'center',
      columnGap: 0.5,
      minHeight: (theme) => theme.spacing(2.5),
      py: 0,
      px: {
        xs: 0.75,
        md: 1,
      },
      borderRadius: (theme) => theme.spacing(2.75),
      borderTopRightRadius: (theme) => ({
        xs: 0,
        md: theme.spacing(2.75),
      }),
      borderBottomRightRadius: (theme) => ({
        xs: 0,
        md: theme.spacing(2.75),
      }),
      color: 'neutral.100',
      backgroundColor: 'neutral.700',

      '&:hover': {
        backgroundColor: 'neutral.600',

        '& > span': {
          backgroundColor: 'primary.dark',
        },
      },
    },
  },
  amount: {
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: (theme) => ({
      xs: theme.spacing(1),
      lg: theme.spacing(1.25),
    }),
    fontWeight: 600,

    '& > b': {
      fontFamily: 'Inter',
      fontSize: (theme) => theme.spacing(0.75),
      fontWeight: 500,
      color: 'primary.main',
    },
  },
  wrapPlus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: (theme) => theme.spacing(2.5),
    height: (theme) => theme.spacing(2.5),
    mr: (theme) => theme.spacing(-1.1),
    p: 0,
    borderTopLeftRadius: '50%',
    borderTopRightRadius: {
      xs: 0,
      md: '50%',
    },
    borderBottomRightRadius: {
      xs: 0,
      md: '50%',
    },
    borderBottomLeftRadius: '50%',
    backgroundColor: 'primary.main',
    transition: 'inherit',
  },
}

export default styles
