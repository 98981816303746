const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    height: (theme) => theme.spacing(12.5),
    p: 1.5,
    boxShadow: 1,
    borderRadius: (theme) => theme.spacing(1.25),
    backgroundColor: 'neutral.100',
  },
  top: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.75,
  },
  icon: {
    display: 'flex',
    fontSize: (theme) => theme.spacing(1.75),
    color: 'neutral.400',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardData: {
    fontSize: (theme) => ({
      xs: theme.spacing(0.75),
      md: theme.spacing(0.75),
    }),
    fontWeight: 500,
    color: 'neutral.500',
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
    borderRadius: '50%',
    fontSize: (theme) => theme.spacing(1.5),
    color: 'neutral.700',
    backgroundColor: 'neutral.300',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: 'neutral.200',
    },
  },
}

export default styles
