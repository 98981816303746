import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog } from '@mui/material'

import { Auth } from '../../../business'
import { SocialLoginButtons } from '../../components'

import View from './View'

const FORM_ID = 'login-form'

const Login = () => {
  const navigate = useNavigate()

  const { status, isSubmitSuccess, submitForm } = Auth.useLoginForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  useEffect(() => {
    if (isSubmitSuccess()) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccess])

  return (
    <Dialog aria-labelledby='loginButton' open>
      <View
        onCancel={() => navigate('/')}
        onGoToRegister={() => navigate('/register')}
        onGoToForgottenPassword={() => navigate('/forgotten-password')}
        formId={FORM_ID}
        onSubmit={handleSubmit}
        {...status}
        renderSocialLogin={() => <SocialLoginButtons skin='dark' />}
      />
    </Dialog>
  )
}

export default Login
