import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Session, User } from '../../../../business'

import Confirm from './Confirm'
import HeaderActions from './Actions'
import Cta from './Cta'
import HeaderContainer from './Container'

const PATHS_WITH_TOPUP_CTA_DISABLED = [
  '/dashboard/top-up-balance',
  '/dashboard/bank-card-edition',
]

const DashboardHeader = () => {
  const { state: balance } = User.useBalance()
  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false)

  const handleClickLogout = () => setConfirmLogoutOpen(true)
  const handleCloseLogout = () => setConfirmLogoutOpen(false)
  const handleConfirmLogout = () => Session.logout()

  const [balanceIsInViewport, setBalanceIsInViewport] = useState(false)

  const { pathname } = useLocation()

  const isTopUpBalanceCTADisabled =
    PATHS_WITH_TOPUP_CTA_DISABLED.includes(pathname)

  const isTopUpBalanceCTAVisible = !balanceIsInViewport

  useEffect(() => {
    document.addEventListener('balanceIsVisible', ({ detail }) =>
      setBalanceIsInViewport(detail),
    )

    return () => {
      document.removeEventListener('balanceIsVisible', () => {})
    }
  }, [])

  return (
    <>
      <HeaderContainer
        renderActions={() => (
          <HeaderActions onClickLogout={handleClickLogout} />
        )}
        renderBalanceCta={() =>
          isTopUpBalanceCTAVisible ? (
            <Cta balance={balance} disabled={isTopUpBalanceCTADisabled} />
          ) : null
        }
      />
      {confirmLogoutOpen ? (
        <Confirm onConfirm={handleConfirmLogout} onCancel={handleCloseLogout} />
      ) : null}
    </>
  )
}

export default DashboardHeader
