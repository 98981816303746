import PropTypes from 'prop-types'

import { Auth } from '../../../business'

import { CircularProgress } from '../index'
import SponsoredModalWrapper from '../Modal/SponsoredModalWrapper'
import View from './View'

const ssoLoginProptypes = {
  loading: PropTypes.bool,
  mediaLogoUrl: PropTypes.string,
  customTitle: PropTypes.string,
  customDescription: PropTypes.string,
  onCancel: PropTypes.func,
  formId: PropTypes.string,
  onGoToRegister: PropTypes.func,
  renderSocialLogin: PropTypes.func,
  onSuccess: PropTypes.func,
  registerCTACustomLabel: PropTypes.string,
  loginCTACustomLabel: PropTypes.string,
}

const SSOLogin = ({
  loading,
  logoUrl,
  customTitle,
  customDescription,
  onCancel,
  formId,
  onGoToRegister,
  onGoToForgottenPassword,
  renderSocialLogin,
  onSuccess,
  registerCTACustomLabel,
  loginCTACustomLabel,
}) => {
  const wrapperProps = {
    logoUrl,
    customTitle,
    customDescription,
    onCancel,
  }

  const { status, isSubmitSuccess, submitForm } = Auth.useLoginForm(formId)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    await submitForm(formValues)
  }

  if (isSubmitSuccess()) {
    onSuccess()
  }

  if (loading) return <CircularProgress height='80vh' />
  return (
    <SponsoredModalWrapper {...wrapperProps}>
      <View
        formId={formId}
        onSubmit={handleSubmit}
        {...status}
        onGoToRegister={onGoToRegister}
        onGoToForgottenPassword={onGoToForgottenPassword}
        renderSocialLogin={renderSocialLogin}
        registerCTACustomLabel={registerCTACustomLabel}
        loginCTACustomLabel={loginCTACustomLabel}
      />
    </SponsoredModalWrapper>
  )
}

SSOLogin.propTypes = ssoLoginProptypes

export default SSOLogin
