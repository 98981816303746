const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    height: (theme) => theme.spacing(12.5),
    boxShadow: 1,
    borderRadius: (theme) => theme.spacing(1.25),
  },
  top: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    my: {
      xs: 2,
      md: 1.5,
    },
    mx: {
      xs: 1.25,
      md: 1.5,
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.75,
  },
  icon: {
    display: {
      xs: 'none',
      md: 'flex',
    },
    fontSize: (theme) => theme.spacing(2),
    color: 'neutral.400',
  },
  cta: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 1,
    borderRadius: (theme) => theme.spacing(0, 0, 1.25, 1.25),

    '& > .MuiButton-startIcon': {
      mr: 0,
    },
  },
}

export default styles
