const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    mb: 1.75,
  },
  heading: {
    fontSize: (theme) => ({
      xs: theme.spacing(1.25),
      lg: theme.spacing(1.25),
    }),
    fontWeight: 600,
    lineHeight: 1.2,
    color: 'neutral.700',
  },
  text: {
    color: 'neutral.500',
  },
  ctaBicolorLabel: {
    '& i': {
      ml: 0.25,
      fontStyle: 'normal',
      color: 'primary.main',
    },
  },
  wrapFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
}

export default styles
