import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { useTexts } from '../../../../../texts'

import { TopUpSelectorCards, Alert } from '../../../index'

import { ReactComponent as Coins } from './Coins.svg'
import styles from './topUp.styles'

const topUpViewPropTypes = {
  onSubmit: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
  messageErrorOnRequest: PropTypes.string,
  onSkipTopUp: PropTypes.func.isRequired,
}

const AMOUNT_OPTIONS = [1, 5, 10]
const POPULAR_OPTION = AMOUNT_OPTIONS[1]

const TopUpView = ({
  onSubmit,
  isRequesting,
  messageErrorOnRequest,
  onSkipTopUp,
}) => {
  const texts = useTexts()
  const [selected, setSelected] = useState(1)

  return (
    <form id='amount' onSubmit={onSubmit}>
      <Box sx={styles.bg}>
        <TopUpSelectorCards
          options={AMOUNT_OPTIONS}
          popularOption={POPULAR_OPTION}
          selected={selected}
          setSelected={setSelected}
        />

        <Box sx={styles.actions}>
          {Boolean(messageErrorOnRequest) && (
            <Alert
              variant='error'
              withContactLink
              text={messageErrorOnRequest}
            />
          )}

          <Button
            id='register-top-up-btn'
            color='secondary'
            startIcon={<Coins />}
            type='submit'
            form='amount'
            disabled={isRequesting}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: texts.getRegisterTopUpAction(`${selected}€`),
              }}
            />
          </Button>
          <Button
            id='register-skip-top-up-btn'
            variant='text'
            size='small'
            onClick={onSkipTopUp}
          >
            {texts.getRegisterTopUpSkipAction()}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

TopUpView.propTypes = topUpViewPropTypes

export default TopUpView
