import { useState, useEffect } from 'react'

import { fetchPurchases } from './service'

const initialState = {
  isLoading: true,
  data: undefined,
  error: false,
}

const formatDate = (date) => {
  const [day, month, year] = date.split('/').map((str) => Number(str))
  return new Date(year + 2000, month - 1, day)
}

const usePurchases = () => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetchPurchases()

        setState({
          isLoading: false,
          data: result.sort((a, b) => {
            const dateA = formatDate(a.date)
            const dateB = formatDate(b.date)
            return dateA < dateB ? 1 : -1
          }),
          error: false,
        })
      } catch (error) {
        setState({ isLoading: false, data: null, error })
      }
    })()
  }, [])
  return state
}

export default usePurchases
