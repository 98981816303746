import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import RequireAuthenticated from './RequireAuthenticated'
import RequireNotAuthenticated from './RequireNotAuthenticated'
import { PrivateLayout, DynamicLayout, PublicLayout } from './Layout'
import {
  ApplePayTest,
  SSOLogin,
  SSOLoginPartner,
  SSORegister,
  SSORegisterPartner,
  SSOForgottenPassword,
  SSOForgottenPasswordPartner,
  Error,
  TermsPolicy,
  PrivacyPolicy,
  LegalNotice,
  Login,
  CookiesPolicy,
  ForgottenPassword,
  Home,
  BankCardEdition,
  BankCardIntroduction,
  TopUpBalance,
  PersonalDataEdition,
  DashboardHome,
  Register,
  SuccessSocialLogin,
  SocialLoginCallback,
  SSOSuccessSocialLogin,
  SSOSocialLoginCallback,
  ThirdPartyIdpRegister,
  SSOThirdPartyIdpRegister,
  WidgetPurchaseWithBankCardRegistration,
} from './Pages'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/apple-pay-test-JLzvTP1O',
    element: <ApplePayTest />,
  },
  {
    path: '/hub',
    element: <div></div>,
  },
  {
    path: '/purchase-with-bank-card-registration',
    element: <WidgetPurchaseWithBankCardRegistration />,
  },
  {
    path: '/sso-login',
    element: <SSOLogin />,
  },
  {
    path: '/sso-login-partner',
    element: <SSOLoginPartner />,
  },
  {
    path: '/sso-forgotten-password',
    element: <SSOForgottenPassword />,
  },
  {
    path: '/sso-forgotten-password-partner',
    element: <SSOForgottenPasswordPartner />,
  },
  {
    path: '/sso-register',
    element: <SSORegister />,
  },
  {
    path: '/sso-register-partner',
    element: <SSORegisterPartner />,
  },
  {
    path: '/sso-3rd-party-idp-register',
    element: <SSOThirdPartyIdpRegister />,
  },
  {
    path: '/3rd-party-idp-register',
    element: <ThirdPartyIdpRegister />,
  },
  {
    path: '/success-social-login',
    element: <SuccessSocialLogin />,
  },
  {
    path: '/social-login-callback',
    element: <SocialLoginCallback />,
  },
  {
    path: '/sso-success-social-login',
    element: <SSOSuccessSocialLogin />,
  },
  {
    path: '/sso-social-login-callback',
    element: <SSOSocialLoginCallback />,
  },
  {
    path: '/privacy-policy',
    element: (
      <DynamicLayout>
        <PrivacyPolicy />
      </DynamicLayout>
    ),
  },
  {
    path: '/terms-policy',
    element: (
      <DynamicLayout>
        <TermsPolicy />
      </DynamicLayout>
    ),
  },
  {
    path: '/legal-notice',
    element: (
      <DynamicLayout>
        <LegalNotice />
      </DynamicLayout>
    ),
  },
  {
    path: '/cookies-policy',
    element: (
      <DynamicLayout>
        <CookiesPolicy />
      </DynamicLayout>
    ),
  },
  {
    path: '/dashboard/',
    element: (
      <RequireAuthenticated>
        <PrivateLayout />
      </RequireAuthenticated>
    ),
    children: [
      {
        index: true,
        element: <DashboardHome />,
      },
      {
        path: 'top-up-balance',
        element: <TopUpBalance />,
      },
      {
        path: 'bank-card-edition',
        element: <BankCardEdition />,
      },
      {
        path: 'bank-card-introduction',
        element: <BankCardIntroduction />,
      },
      {
        path: 'personal-data-edition',
        element: <PersonalDataEdition />,
      },
    ],
  },
  {
    path: '/register',
    element: (
      <RequireNotAuthenticated>
        <Register />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/login',
    element: (
      <RequireNotAuthenticated>
        <Login />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/forgotten-password',
    element: (
      <RequireNotAuthenticated>
        <ForgottenPassword />
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
  {
    path: '/',
    element: (
      <RequireNotAuthenticated>
        <PublicLayout>
          <Home />
        </PublicLayout>
      </RequireNotAuthenticated>
    ),
    errorElement: <Error />,
  },
])

const App = () => {
  return <RouterProvider router={router} />
}

export default App
