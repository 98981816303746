const styles = {
  root: {
    width: (theme) => ({
      xs: `calc(100% - ${theme.spacing(2)})`,
      sm: '100%',
    }),
    heigth: '100vh',
    mx: 'auto',
    p: (theme) => ({
      xs: theme.spacing(2),
      md: theme.spacing(3),
      lg: theme.spacing(8, 4, 9),
      xl: theme.spacing(8, 8.75, 9),
    }),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 2,
  },
  title: {
    textAlign: 'center',
  },
}

export default styles
