import { useEffect } from 'react'

import { Auth } from '../../../../../business'

import View from './View'

const FORM_ID = 'register-user-data-form'

const UserStep = ({
  onGoToLogin,
  onContinue,
  renderSocialLogin,
  withExplicitLegalAcceptance,
}) => {
  const useForm = withExplicitLegalAcceptance
    ? Auth.useUserDataFormWithAcceptAllLegalCTA
    : Auth.useUserDataFormWithAutoAcceptAllLegalCTA

  const {
    status,
    submitForm,
    showsAcceptAllLegalCTA,
    acceptAllLegalRequirements,
    updateLegalCheckbox,
    ctaLabel,
  } = useForm(FORM_ID)

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    const formData = new FormData(ev.target)
    const formValues = Object.fromEntries(formData)
    submitForm(formValues)
  }

  useEffect(() => {
    if (status.result !== null) {
      onContinue({ ...status.result, password: status.formValues.password })
    }
  }, [status, onContinue])

  return (
    <View
      formId={FORM_ID}
      onSubmit={handleSubmit}
      onChangeControlledLegalCheckbox={updateLegalCheckbox}
      showsAcceptAllLegalRequirementsCTA={showsAcceptAllLegalCTA}
      onClickAcceptAllLegalRequirements={acceptAllLegalRequirements}
      ctaLabel={ctaLabel}
      {...status}
      onGoToLogin={onGoToLogin}
      renderSocialLogin={renderSocialLogin}
    />
  )
}

export default UserStep
