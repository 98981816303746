import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import View from './View'

const Hero = forwardRef((_, ref) => {
  const navigate = useNavigate()

  return <View ref={ref} openRegister={() => navigate('/register')} />
})

export default Hero
