const styles = {
  dashboardHome: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: 2.5,
    pt: {
      xs: 2,
      md: 2.5,
    },
  },
}

export default styles
