import { Dialog } from '@mui/material'

import { Registration, SocialLoginButtons } from '../../components'

const Register = () => {
  return (
    <Dialog aria-labelledby='registerButton' open>
      <Registration
        initialStepIndex={0}
        renderSocialLogin={() => <SocialLoginButtons />}
      />
    </Dialog>
  )
}

export default Register
