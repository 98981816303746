import { Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'

import { useTexts } from '../../../texts'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
import { CookiesLayer } from '../../components'
import { BalanceProvider, BankCardProvider } from '../../../business/user'
import Header from './Header'
import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'
import styles from './private.styles'

const BREADCRUMBS_LABEL_MAP = {
  '/dashboard/top-up-balance': (texts) =>
    texts.getBreadcrumbsTopUpBalanceLabel(),
  '/dashboard/bank-card-edition': (texts) =>
    texts.getBreadcrumbsBankCardEditionLabel(),
  '/dashboard/bank-card-introduction': (texts) =>
    texts.getBreadcrumbsBankCardIntroLabel(),
  '/dashboard/personal-data-edition': (texts) =>
    texts.getBreadcrumbsPersonalDataEditionLabel(),
}

const PrivateLayout = ({ children }) => {
  const texts = useTexts()
  const location = useLocation()

  const breadcrumbsLabelCallback = BREADCRUMBS_LABEL_MAP?.[location.pathname]
  const hasBreadcrumbs = Boolean(breadcrumbsLabelCallback)

  return (
    <ScrollRevisionOnPageChange>
      <BalanceProvider>
        <BankCardProvider>
          <Header />
          <Box component='main' aria-label='dashboard' sx={styles.root}>
            <Box sx={styles.mainContent}>
              {hasBreadcrumbs && (
                <Breadcrumbs currentPage={breadcrumbsLabelCallback(texts)} />
              )}

              <Outlet />
              {children}
            </Box>
          </Box>
        </BankCardProvider>
      </BalanceProvider>
      <Footer />
      <CookiesLayer />
    </ScrollRevisionOnPageChange>
  )
}

export default PrivateLayout
