import { useNavigate } from 'react-router-dom'

import { ForgottenPasswordComponent } from '../components'

const ForgottenPassword = () => {
  const navigate = useNavigate()

  const cancel = () => navigate('/')

  const goToLogin = () => navigate('/login')

  return <ForgottenPasswordComponent onCancel={cancel} goToLogin={goToLogin} />
}

export default ForgottenPassword
