import jwtDecode from 'jwt-decode'

import { URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS } from '../App/Pages/constants'
import {
  login as loginService,
  logout as logoutService,
  refreshSession as refreshSessionService,
} from './auth'

const ID_TOKEN_STORAGE_KEY = 'payper_id_token'
const ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY =
  'payper_id_token_expiration_timestamp'
const ACCESS_TOKEN_STORAGE_KEY = 'payper_access_token'
const ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY =
  'payper_access_token_expiration_timestamp'
const REFRESH_TOKEN_STORAGE_KEY = 'payper_refresh_token'

export const storeTokens = (tokens) => {
  window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, tokens.refreshToken)
  window.localStorage.setItem(ID_TOKEN_STORAGE_KEY, tokens.idToken)
  window.localStorage.setItem(
    ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY,
    JSON.stringify(tokens.idTokenExpiresAt),
  )
  window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, tokens.accessToken)
  window.localStorage.setItem(
    ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY,
    JSON.stringify(tokens.accessTokenExpiresAt),
  )
}

const clearLocalStorage = () => {
  window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ID_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)
}

export const getAccessTokenExpiration = () =>
  window.localStorage.getItem(ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)

export const getIdToken = () =>
  window.localStorage.getItem(ID_TOKEN_STORAGE_KEY)

export const getAccessToken = () =>
  window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)

export const getRefreshToken = () =>
  window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)

export const isAuthenticated = () => !!getRefreshToken() && !!getAccessToken()

const isTokenExpired = (tokenExpiration) => {
  const nowTimestampInSecs = new Date().getTime() / 1000
  return nowTimestampInSecs > tokenExpiration
}

export const hasValidAccessToken = () =>
  !isTokenExpired(getAccessTokenExpiration())

export const getUserInfoFromIdToken = () => {
  if (!Boolean(getIdToken())) return null
  return jwtDecode(getIdToken())
}

export const getUserSub = () => {
  return getUserInfoFromIdToken()?.sub ?? null
}

export const login = async (username, password) => {
  const userData = await loginService(username, password)
  storeTokens(userData)
  return getUserSub()
}

export const logout = async () => {
  const refreshToken = getRefreshToken()

  await logoutService(refreshToken)
  clearLocalStorage()
  window.location.reload()
}

export const refreshSession = async () => {
  const { email } = getUserInfoFromIdToken()
  const tokens = await refreshSessionService(email, getRefreshToken())
  if (tokens) {
    storeTokens(tokens)
  } else {
    logout()
  }
}

export const appendAccessParamsToURL = (url) => {
  const redirectUrl = new URL(url)
  const searchParams = new URLSearchParams(redirectUrl.search)

  searchParams.append(
    URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.REFRESH_TOKEN,
    getRefreshToken(),
  )

  const { email } = getUserInfoFromIdToken()
  searchParams.append(URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.EMAIL, email)

  searchParams.append(URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.ACCESS_TOKEN, '1')

  return `${redirectUrl.origin}${
    redirectUrl.pathname ?? ''
  }?${searchParams.toString()}`
}
