import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'

import styles from './accountData.styles'

const ROOT_MARGIN = '-64px'

const View = ({ children }) => {
  const ref = useRef(null)
  const [isIntersecting, setIntersecting] = useState(true)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
        const event = new CustomEvent('balanceIsVisible', {
          detail: isIntersecting,
        })
        document.dispatchEvent(event)
      },
      { rootMargin: ROOT_MARGIN },
    )

    observer.observe(ref.current)
    return () => {
      const event = new CustomEvent('balanceIsVisible', {
        detail: false,
      })
      document.dispatchEvent(event)
      observer.disconnect()
    }
  }, [isIntersecting, ref])

  return (
    <Box component='aside' sx={styles.root} ref={ref}>
      <Box sx={styles.container}>{children}</Box>
    </Box>
  )
}

export default View
