import { useLocation, useNavigate } from 'react-router-dom'

import useSSO from './useSSO'
import useOnLoadSSO from './useOnLoadSSO'

import {
  SSOSocialLoginButtons,
  SSOLogin as SSOLoginComponent,
} from '../components'

const SSO_LOGIN_FORM_ID = 'sso-login-form'

const SSOLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const sso = useSSO()
  const { unauthenticationIsConfirmed } = useOnLoadSSO(
    sso.getDataForAnalytics,
    sso.redirect,
  )

  const handleGoToRegister = async () => {
    navigate(`/sso-register${location.search}`)
  }

  const handleGoToForgottenPassword = async () => {
    navigate(`/sso-forgotten-password${location.search}`)
  }

  const handleSuccess = () => {
    sso.redirect()
    return
  }

  const cancel = () => {
    sso.redirectWithNoSession()
  }

  return (
    <SSOLoginComponent
      loading={!unauthenticationIsConfirmed}
      logoUrl={sso.getMediaLogoUrl()}
      customTitle={sso.getMediaTitle()}
      customDescription={sso.getMediaDescription()}
      onCancel={cancel}
      formId={SSO_LOGIN_FORM_ID}
      onGoToRegister={handleGoToRegister}
      onGoToForgottenPassword={handleGoToForgottenPassword}
      renderSocialLogin={() => <SSOSocialLoginButtons />}
      onSuccess={handleSuccess}
    />
  )
}

export default SSOLogin
