import { Link } from 'react-router-dom'
import { Button, Typography, CircularProgress, Box } from '@mui/material'

import { formatNumber } from '../../../../format'
import { useTexts } from '../../../../../texts'
import { User } from '../../../../../business'
import CardWrapper from '../CardWrapper'

import { ReactComponent as Logo } from './logo-payper.svg'
import { ReactComponent as Coins } from './Coins.svg'
import { ReactComponent as Contactless } from './Contactless.svg'
import styles from './balance.styles'

const BalanceCard = ({ withTopUp }) => {
  const texts = useTexts()
  const { state: balance } = User.useBalance()

  return (
    <>
      {balance.isLoading ? (
        <CircularProgress />
      ) : balance.error ? (
        <p>{texts.getError()}</p>
      ) : (
        <CardWrapper
          shrink
          title={texts.getBalanceCurrentLabel()}
          icon={<Coins />}
        >
          <Box sx={styles.card}>
            <Box sx={styles.info}>
              <Box sx={styles.top}>
                <Typography sx={styles.amount}>
                  {formatNumber(balance.data)} <span>€</span>
                </Typography>
                <Box sx={styles.icon}>
                  <Contactless />
                </Box>
              </Box>
            </Box>
            {withTopUp ? (
              <Button
                id='dashboard-top-up-btn'
                fullWidth
                color='primary'
                sx={styles.cta}
                component={Link}
                to='top-up-balance'
              >
                {texts.getBalanceTopUpAction()}
              </Button>
            ) : (
              <Box sx={styles.noCta}>
                <Logo />
              </Box>
            )}
          </Box>
        </CardWrapper>
      )}
    </>
  )
}

export default BalanceCard
