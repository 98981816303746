import { useState } from 'react'

import { Session, User } from '../../../business'
import { BankCardRegistration } from '../../components'
import View from './View'
import SuccessView from './SuccessView'

const BankCardChange = ({ title, successTitle, successSubtitle }) => {
  const user = Session.getUserInfoFromIdToken()

  const { state: bankCard, refetchBankCard } = User.useBankCard()

  const [showSuccess, setShowSuccess] = useState(false)

  const handleSuccess = async () => {
    await refetchBankCard()
    setShowSuccess(true)
  }

  return (
    <>
      {showSuccess ? (
        <SuccessView
          bankCard={bankCard}
          title={successTitle}
          subtitle={successSubtitle}
        />
      ) : Boolean(user.sub) ? (
        <View title={title} bankCardData={bankCard.data}>
          <BankCardRegistration onSuccess={handleSuccess} sub={user.sub} />
        </View>
      ) : null}
    </>
  )
}

export default BankCardChange
