import { useLocation, useNavigate } from 'react-router-dom'

import { User } from '../../business'
import {
  SSOUserRegistration,
  SSOPartnerSocialLoginButtons,
} from '../components'
import useSSOPartner from './useSSOPartner'
import useOnLoadSSO from './useOnLoadSSO'

const SSORegisterPartner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const ssoPartner = useSSOPartner()
  const { unauthenticationIsConfirmed } = useOnLoadSSO(
    ssoPartner.getDataForAnalytics,
    ssoPartner.redirect,
  )

  const navigateToLogin = () => {
    navigate(`/sso-login-partner${location.search}`)
  }

  const handleFinish = async () => {
    await User.createBonus(
      ssoPartner.getBonusToken(),
      ssoPartner.getPartnerId(),
    )

    ssoPartner.redirect()
  }

  const handleFinishWithError = () => {
    navigate('/')
  }

  return (
    <SSOUserRegistration
      loading={!unauthenticationIsConfirmed}
      logoUrl={ssoPartner.getPartnerLogoUrl()}
      customTitle={ssoPartner.getPartnerTitle()}
      customDescription={ssoPartner.getPartnerDescription()}
      onClickLogin={navigateToLogin}
      renderSocialLogin={() => <SSOPartnerSocialLoginButtons />}
      onFinish={handleFinish}
      onFinishWithError={handleFinishWithError}
    />
  )
}

export default SSORegisterPartner
