import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

import { ReactComponent as ArrowRight } from './ArrowRight.svg'

const ContinueButton = React.forwardRef((props, ref) => {
  const { children, ...rest } = props
  return (
    <LoadingButton
      color='secondary'
      endIcon={<ArrowRight />}
      ref={ref}
      {...rest}
    >
      {children}
    </LoadingButton>
  )
})

export default ContinueButton
