const styles = {
  bg: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    py: 2,
    position: 'relative',
    backgroundColor: 'neutral.200',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 50vw)',
      zIndex: 0,
      backgroundColor: 'inherit',
    },

    '& > *': {
      position: 'relative',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    width: (theme) => `min(100%, ${theme.spacing(20)})`,
    mx: 'auto',
  },
}

export default styles
