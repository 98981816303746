import { useNavigate } from 'react-router-dom'

import View from './View'

const Benefits = () => {
  const navigate = useNavigate()

  return <View openRegister={() => navigate('/register')} />
}

export default Benefits
