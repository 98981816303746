const styles = {
  root: {
    py: {
      xs: 0.75,
      md: 1.5,
    },
    backgroundColor: (theme) =>
      `var(--root-bgColor, ${theme.palette.neutral[700]})`,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: {
      md: 'end',
    },
    gap: (theme) => theme.spacing(0.5, 1),
    my: 0,
    pl: 0,
    pr: {
      md: 3,
    },
    listStyleType: 'none',
  },
  item: {
    '&:not(:last-of-type)': {
      position: 'relative',

      '&::before': {
        content: '""',
        display: 'block',
        width: '1px',
        height: (theme) => theme.spacing(1),
        position: 'absolute',
        top: (theme) => theme.spacing(0.25),
        right: (theme) => theme.spacing(-0.5),
        backgroundColor: 'neutral.400',
      },
    },
  },
  link: {
    fontSize: (theme) => theme.spacing(0.75),
    color: (theme) => `var(--link-color, ${theme.palette.neutral[100]})`,
    textDecoration: 'none',
  },
}

export default styles
