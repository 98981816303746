// docu on Displaying Apple Pay Buttons Using JS or CSS
//developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_javascript
// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_css

import { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'

import { ApplePay, User } from '../../../../../business'
import { useTexts } from '../../../../../texts'
import { TopUpSelectorCards, Alert } from '../../../index'
import styles from './applePayTopUp.styles'

const AMOUNT_OPTIONS = [1, 5, 10]
const POPULAR_OPTION = AMOUNT_OPTIONS[1]
const DEFAULT_OPTION = AMOUNT_OPTIONS[0]

const ApplePayTopUp = ({ onClickIntroBankCard, onContinue }) => {
  const texts = useTexts()

  const [selectedAmount, setSelectedAmount] = useState(DEFAULT_OPTION)
  const [errorMessage, setErrorMessage] = useState('')

  const handleTransactionError = (error) => {
    let errorMessage = texts.getApplePayTopUpError()

    if (error instanceof User.RedsysError) {
      const redsysErrorMessage = User.getRedsysDSResponseMessageTranslation(
        error.message,
      )
      if (Boolean(redsysErrorMessage)) errorMessage = redsysErrorMessage
    }

    setErrorMessage(errorMessage)
  }

  const handleApplePayTransaction = async () => {
    setErrorMessage('')

    await ApplePay.handleTransaction(
      selectedAmount,
      onContinue,
      handleTransactionError,
    )
  }

  useEffect(() => {
    const onClickApplePayButton = async () => {
      setErrorMessage('')

      const fakeButton = document.getElementById('fake-apple-pay-button')
      fakeButton.click()
    }

    const applePayButton = document.querySelector('apple-pay-button')
    applePayButton.addEventListener('click', onClickApplePayButton)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={styles.root}>
      <Box sx={styles.form}>
        <TopUpSelectorCards
          sx={styles.topUpCards}
          options={AMOUNT_OPTIONS}
          popularOption={POPULAR_OPTION}
          selected={selectedAmount}
          setSelected={setSelectedAmount}
        />
        <Box sx={styles.actions}>
          {Boolean(errorMessage) && (
            <Alert variant='error' withContactLink text={errorMessage} />
          )}
          <div id='apple-pay-button-container'>
            <apple-pay-button
              buttonstyle='black'
              type='plain'
              locale='es-ES'
            ></apple-pay-button>
            <button
              id='fake-apple-pay-button'
              style={{ display: 'none' }}
              onClick={handleApplePayTransaction}
            />
          </div>
          <Button size='small' fullWidth onClick={onClickIntroBankCard}>
            {texts.getGoToIntroBankCardAction()}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ApplePayTopUp
