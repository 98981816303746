import { Box, Button, Dialog, Typography } from '@mui/material'
import { useTexts } from '../../../../texts'

import styles from './header.styles'

const Confirm = ({ onConfirm, onCancel }) => {
  const texts = useTexts()

  return (
    <Dialog
      open
      onClose={onCancel}
      aria-labelledby='alert-dialog-title'
      sx={styles.confirm}
    >
      <Typography id='alert-dialog-title' sx={styles.confirmTitle}>
        {texts.getLogoutTitleLabel()}
      </Typography>
      <Box sx={styles.confirmActions}>
        <Button fullWidth onClick={onCancel}>
          {texts.getCancelAction()}
        </Button>
        <Button fullWidth color='secondary' onClick={onConfirm}>
          {texts.getConfirmAction()}
        </Button>
      </Box>
    </Dialog>
  )
}

export default Confirm
