import { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts'
import { Alert, Decorator, FormSkinWrapper, TextField } from '../../index'
import { ReactComponent as LockSimple } from './LockSimple.svg'
import { ReactComponent as EnvelopeSimple } from './EnvelopeSimple.svg'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as EyeClosed } from './EyeClosed.svg'
import styles from './view.styles'

const SSOLoginView = ({
  formId,
  onSubmit,
  isSubmitting,
  formError,
  fieldErrors,
  onGoToRegister,
  renderSocialLogin,
  registerCTACustomLabel,
  loginCTACustomLabel,
  onGoToForgottenPassword,
}) => {
  const texts = useTexts()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Box sx={styles.root}>
      <Box>
        <Box sx={styles.header}>
          <Typography component='h2' sx={styles.heading}>
            {texts.getLoginWidgetRegisterLabel()}
          </Typography>
          <Typography sx={styles.text}>
            {texts.getLoginWidgetRegisterTextLabel()}
          </Typography>
        </Box>
        <Button
          fullWidth
          color='secondary'
          onClick={onGoToRegister}
          sx={styles.ctaBicolorLabel}
        >
          <span
            dangerouslySetInnerHTML={{
              __html:
                registerCTACustomLabel ??
                texts.getLoginWidgetRegisterCtaLabel(),
            }}
          />
        </Button>
      </Box>
      <Decorator />
      <Box>
        <Box sx={styles.header}>
          <Typography component='h2' sx={styles.heading}>
            {texts.getLoginWidgetSocialLabel()}
          </Typography>
        </Box>
        {renderSocialLogin()}
      </Box>
      <Decorator />
      <Box>
        <Box sx={styles.header}>
          <Typography component='h2' sx={styles.heading}>
            {texts.getLoginWidgetLoginLabel()}
          </Typography>
        </Box>

        <form id={formId} onSubmit={onSubmit} noValidate>
          <FormSkinWrapper skin='light' sx={styles.wrapFields}>
            <TextField
              id='user'
              name='user'
              label={texts.getLoginFormNameLabel()}
              type='email'
              autoComplete='user'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EnvelopeSimple />
                  </InputAdornment>
                ),
              }}
              helperText={fieldErrors.user}
              error={Boolean(fieldErrors.user)}
              inputProps={{ inputMode: 'email' }}
              required
            />
            <TextField
              id='password'
              name='password'
              label={texts.getLoginFormPasswordLabel()}
              autoComplete='current-password'
              type={showPassword ? 'text' : 'password'}
              helperText={fieldErrors.password}
              error={Boolean(fieldErrors.password)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockSimple />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position='end'
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    <IconButton aria-label={texts.getTogglePasswordAction()}>
                      {showPassword ? <Eye /> : <EyeClosed />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            {Boolean(formError) ? (
              <Alert variant='error' text={formError} />
            ) : null}
            <LoadingButton
              color='secondary'
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
              loadingIndicator={<CircularProgress size={16} />}
              sx={styles.ctaBicolorLabel}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    loginCTACustomLabel ?? texts.getLoginFormSubmitAction(),
                }}
              />
            </LoadingButton>
            <Button
              id='forgotten-password-btn'
              variant='text'
              size='small'
              onClick={onGoToForgottenPassword}
              fullWidth
            >
              {texts.getForgottenPasswordAction()}
            </Button>
          </FormSkinWrapper>
        </form>
      </Box>
    </Box>
  )
}

export default SSOLoginView
